.m-navigation-flyout {
	--_inset-block-start: calc(var(--block-size-header) - calc(4rem / 16)); /* calc(4rem / 16) is padding-block-end of .o-header */
	--_color-content: var(--color-content-primary);
	--_color-background: var(--color-background-primary);

	position: absolute;
	inset-block-start: var(--_inset-block-start);
	display: none;
	inline-size: 100%;
	block-size: calc(200% - var(--_inset-block-start));
	background-color: transparent;
	background-clip: content-box;
	pointer-events: none;

	&[data-show-popover] {
		inset-inline-start: 0;
		inset-inline-end: 0;
		display: block;
	}

	body[data-inverse] & {
		--_color-content: var(--color-content-inverse-primary);
		--_color-background: var(--color-background-inverse-primary);
	}

	@media not (min-width: 61em) {
		display: none;
	}
}

.m-navigation-flyout__container {
	display: flow-root;
	background-color: var(--_color-background);
	pointer-events: auto;
	overflow: hidden;
}

.m-navigation-flyout__list {
	margin-inline: auto;
	max-inline-size: calc(var(--inline-size-content) + var(--gap-small) * 2);
	display: grid;
	padding-inline: var(--gap-small);
	padding-block-start: var(--gap-medium);
	padding-block-end: calc(var(--gap-medium) + var(--gap-small));
	grid-template-columns: repeat(12, 1fr);
	row-gap: var(--gap-small);
	column-gap: var(--gap-grid);
	list-style: none;

	> li {
		grid-column-end: span 3;
	}
}
