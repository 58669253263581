.StripeElement {
	--_padding-block: var(--gap-xx-small);
	--_padding-inline: var(--gap-x-small);
	--_color-content: var(--color-content-primary);
	--_color-background: var(--color-background-secondary);
	--_color-background-focus: color-mix(in hsl, var(--_color-background), var(--color-white) 20%);
	--_border-color: transparent;
	--_border-color-focus: var(--_color-content);
	--_font: var(--font-paragraph-medium);

	display: flex;
	align-items: center;
	box-sizing: border-box;
	inline-size: 100%;
	min-block-size: calc(1lh + var(--_padding-block) * 2 + var(--size-border) * 2);
	padding-block: var(--_padding-block);
	padding-inline: var(--_padding-inline);
	font: var(--_font);
	color: var(--_color-content);
	resize: none;
	border: var(--size-border) solid var(--_border-color);
	background-color: var(--_color-background);

	> *  {
		inline-size: 100%;
	}
}
.StripeElement--focus {
	color: var(--_color-content);
	border-color: var(--_border-color-focus);
	background-color: var(--_color-background-focus);
	outline: none;
}
