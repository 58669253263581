.o-header {
	--_color-background: var(--color-background-primary);

	body[data-inverse] & {
		--_color-background: var(--color-background-inverse-primary);
	}

	@media print {
		--_color-background: transparent;
	}
}

.o-header__backdrop {
	position: fixed;
	inset: 0;
	z-index: calc(var(--z-index-header) - 1);
	background-color: var(--color-backdrop);
	pointer-events: none;
	opacity: 0;
	transition-duration: var(--duration-medium);

	&[data-visible] {
		opacity: 1;
		pointer-events: auto;
		transition-duration: var(--duration-x-large);
	}
}

.o-header__box {
	position: relative;
	z-index: var(--z-index-header);
	min-block-size: var(--block-size-header);
	display: flex;
	align-items: end;
	padding-block-end: calc(4rem / 16);
	background-color: var(--_color-background);
}

.o-header__container {
	/* padding-inline of .a-button[data-variant="navigation-text-secondary"] */
	--_padding-inline-a-button: var(--gap-x-small);

	margin-inline: auto;
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	flex-basis: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: end;

	@media not (min-width: 61em) {
		/* Offscreen navigation */
		align-items: center;
	}
	@media (min-width: 66em) {
		--_padding-inline-a-button: var(--gap-small);
	}
	@media (min-width: calc(1120em / 16)) {
		padding-inline-end: calc(var(--margin-inline-body) - var(--_padding-inline-a-button));
	}
}

.o-header__navigation {
	@media not (min-width: calc(1120em / 16)) {
		padding-inline: var(--gap-x-small);
	}
}

.o-header__logo {
	padding-block: var(--gap-x-small);
	padding-inline: var(--margin-inline-body);
	margin-block-end: calc(1rem / 16);
	transition-property: opacity;
	transition-duration: var(--duration-x-small);

	> img {
		display: block;
		block-size: calc(32rem / 16);
		inline-size: auto;
	}

	@media (pointer: fine) {
		&:hover {
			opacity: 0.8;
		}
		&:active {
			opacity: 0.7;
		}

		body[data-inverse] & {
			&:hover {
				opacity: 0.9;
			}
			&:active {
				opacity: 0.7;
			}
		}
	}

	@media print {
		margin-block-start: var(--gap-medium);

		> img {
			block-size: calc(48rem / 16);
		}
	}
}

.o-header__noscript {
	margin-inline: auto;
	max-inline-size: calc(var(--inline-size-content));

	> div {
		padding: var(--gap-medium) var(--gap-large);
		background-color: var(--color-background-warning);
	}
}
