.m-roomle-configuration {
	--_image-size: calc(72rem / 16);
	--_column-gap: var(--gap-small);

	margin-block-start: var(--gap-grid);
	display: grid;
	grid-template-columns: var(--_image-size) 1fr;
	align-items: end;
	column-gap: var(--_column-gap);

	@media (min-width: 38em) {
		--_image-size: calc(120rem / 16);
		--_column-gap: var(--gap-x-large);
	}
}

.m-roomle-configuration__image {
	position: relative;
	align-self: start;
	display: flex;
	justify-content: center;
	align-items: center;

	> img {
		transition-property: opacity;
		transition-duration: var(--duration-small);
	}

	&[data-loader] {
		> img {
			opacity: 0.5;
		}

		&::after {
			position: absolute;
			content: '';
			block-size: 1.2em;
			inline-size: 1.2em;
			block-size: 1lh;
			inline-size: 1lh;
			mask-image: url(/assets/images/icons/loader.svg);
			mask-size: 1.2em;
			mask-size: 1lh;
			background-color: var(--color-content-tertiary);
			animation-name: rotate;
			animation-duration: var(--duration-rotate-loader);
			animation-timing-function: linear;
			animation-iteration-count: infinite;
		}
	}
}

.m-roomle-configuration__heading {
	grid-column-start: 2;
	font: var(--font-heading-small);
}

.m-roomle-configuration__info {
	margin-block-start: var(--gap-small);
	grid-column-start: 2;

	> p + p {
		margin-block-start: var(--gap-xx-small)
	}
}

.m-roomle-configuration__details {
	margin-block-start: var(--gap-medium);
	grid-column: 1 / -1;
}
