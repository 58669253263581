.o-order {
	--_columns: 12;
	--_gap: var(--space-small);

	margin-inline: auto;
	margin-block-start: var(--space-x-small);
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	padding-inline: var(--margin-inline-body);
	inline-size: 100%;

	display: grid;
	grid-template-columns: minmax(10px, 1fr);
	gap: var(--_gap);

	@media (min-width: 61em) {
		--_columns: 12;
		--_gap: var(--gap-grid);
		margin-block-start: var(--space-large);
		grid-template-columns: repeat(var(--_columns), 1fr);
		grid-auto-flow: dense;

		> h1 {
			grid-column: 1 / 12;
		}
	}
	@media print {
		margin-block-start: 0.5cm;
		padding-inline-start: 3.7cm;
		padding-inline-end: 0.5cm;
	}
}

.o-order__info {
	@media (min-width: 61em) {
		grid-column: 8 / 13;
		justify-self: end;
	}
}

.o-order__info-delivery-tax {
	@media (min-width: 38.125em) {
		grid-column: 1 / 8;
	}
}

.o-order__address {
	@media (min-width: 61em) {
		grid-column: 1 / 8;
	}
}

.o-order__cart {
	@media (min-width: 61em) {
		grid-column: span var(--_columns);
	}
}

.o-order__buttons {
	@media (min-width: 61em) {
		grid-column: span var(--_columns);
	}
}

:where(.o-order__payment, .o-order__shipping) {
	@media (min-width: 61em) {
		grid-column-end: span calc(var(--_columns) / 2);
	}
}
