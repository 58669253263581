/**
 * Block of three images, one big two small.
 */

 .m-images {
	container-type: inline-size;
 }

 .m-images__container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: var(--gap-small);

	> *:first-child {
		grid-column-end: span 2;
	}

	@container (min-width: 610px) {
		grid-template-columns: repeat(12, 1fr);
		gap: var(--gap-x-large);

		> * {
			grid-column-end: span 4;
		}

		> *:first-child {
			grid-row: 1 / span 2;
			grid-column-end: span 8;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		.m-images[data-reverse] & {
			> *:where(:nth-of-type(2), :nth-of-type(3)) {
				grid-column-start: 1;
			}
			> *:nth-of-type(2) {
				grid-row-start: 1;
			}
			> *:first-child {
				order: 2;
			}
		}
	}
	@container (min-width: 960px) {
		gap: var(--gap-grid);
	}
 }
