.o-product-type {
	margin-inline: auto;
	display: grid;
	inline-size: 100%;
	margin-block-start: var(--space-small);

	@media not (min-width: 72em) {
		max-inline-size: 100%;
		grid-template-columns: var(--margin-inline-body) [content-start] calc(100% - var(--margin-inline-body) * 2) [content-end] var(--margin-inline-body);
		row-gap: var(--gap-grid);
	}
	@media (min-width: 72em) {
		max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
		grid-template-columns: repeat(12, 1fr);
		column-gap: var(--gap-grid);
		align-items: start;
		padding-inline: var(--margin-inline-body);
	}
	@media (min-width: 61em) {
		margin-block-start: var(--space-large);
	}
}

.o-product-type__nav {
	grid-column: content;
	display: flex;
	flex-direction: column;
	align-items: start;
	row-gap: var(--gap-small);

	@media (min-width: 72em) {
		grid-row-start: 1;
		grid-column-start: 1;
		grid-column-end: span 3;
	}
}

.o-product-type__standalone {
	grid-column: content;

	@media (min-width: 72em) {
		grid-column-start: 4;
		grid-column-end: -1;
	}
}

.o-product-type__assortment {
	display: block;
	margin-block-start: var(--gap-large);
}

.o-product-type__assortment-label {
	font: var(--font-label-medium);
	color: var(--color-content-secondary);
}

.o-product-type__assortment-list {
	margin-block-start: var(--gap-small);
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	gap: var(--gap-x-small);

	@media not (min-width: 72em) {
		flex-wrap: nowrap;
	}
}
