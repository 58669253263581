/**
 * Table for the sum of the cart. Includes rows for Zwischensumme, Versandkosten, Netto, Steuern, Summe, or similar.
 */

.a-table-sum {
	--_gap-vertical: calc(6rem / 16);

	inline-size: 100%;
	font: var(--font-label-x-small);

	tfoot {
		font: var(--font-label-medium);
	}

	th {
		text-align: start;
		font-weight: inherit;
		text-wrap: balance;
	}
	td {
		text-align: end;
		font-weight: 700;
	}
	td, th {
		small {
			display: block;
			text-wrap: balance;
			font: var(--font-label-x-small);
			color: var(--color-content-secondary);
		}
	}

	:where(tbody, tfoot) > tr:not([data-small]) > * {
		padding-block: var(--_gap-vertical);
		vertical-align: top;
		border-block-start: calc(1rem / 16) solid currentcolor;
	}
	:where(tbody, tfoot) > tr:has(+ tr[data-small]) > * {
		padding-block-end: var(--gap-xx-small);
	}

	:where(tbody, tfoot) > tr[data-small] > * {
		padding-block-end: var(--_gap-vertical);
		text-align: start;
	}


	tbody[data-kind="secondary"] {
		color: var(--color-content-secondary);
	}

	tbody > tr:first-child > * {
		border-block-start-color: currentcolor;
	}
	tbody:has(+ tfoot) > tr:last-child > * {
		padding-block-end: var(--_gap-vertical);
	}
	tfoot > tr:first-child > * {
		padding-block: var(--_gap-vertical);
		font-weight: 700;
		border-block-start: var(--size-border) solid currentcolor;
	}
}
