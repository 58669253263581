.o-wishlist {
	display: grid;
	grid-auto-flow: dense;
	align-items: start;
	margin-inline: auto;
	margin-block-start: var(--space-small);
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	padding-inline: var(--margin-inline-body);
	row-gap: var(--space-medium);

	@media (min-width: 50em) {
		margin-block-start: var(--space-medium);
		grid-template-columns: repeat(12, 1fr);
		column-gap: var(--gap-grid);
	}

	@media (min-width: 61em) {
		margin-block-start: var(--space-large);
	}

	@media print {
		display: block;
		margin-block-start: 0.5cm;
		padding-inline-start: 3.7cm;
		padding-inline-end: 0.5cm;
	}
}

.o-wishlist__header {
	font: var(--font-display-large);

	@media (min-width: 50em) {
		grid-column: 1 / -1;
	}

	@media print {
		font: var(--font-display-small);
	}
}

.o-wishlist__wishlist {
	display: grid;
	row-gap: calc(var(--gap-grid) - var(--gap-large));

	@media (min-width: 50em) {
		grid-column-end: span 7;
	}

	@media print {
		margin-block-start: var(--space-medium);
	}
}

.o-wishlist__aside {
	display: grid;
	row-gap: var(--space-small);

	@media (min-width: 50em) {
		grid-column-start: span 4;
		grid-column-end: -1;
		row-gap: var(--gap-grid);
	}

	@media print {
		display: none;
	}
}
