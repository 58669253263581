.a-radio {
	appearance: none;
}

.a-radio input {
	opacity: 0;
	width: 0px;
	height: 0px;
	overflow-x: hidden;
	overflow-y: hidden;
	margin-top: 0px;
	padding-top: 0px;
	position: absolute;
}

.a-radio__paint {
	display: grid;
	block-size: calc(20rem / 16);
	inline-size: calc(20rem / 16);
	border-radius: 50%;
	border: var(--size-border) solid var(--color-content-tertiary);
	transition-property: border, background-color;
	transition-duration: var(--duration-small);

	&::after {
		content: '';
		inline-size: calc(8rem / 16);
		block-size: calc(8rem / 16);
		margin: auto;
		border-radius: 50%;
		background-color: var(--color-white);
		scale: 0;
		opacity: 0;
		transition-property: opacity, scale;
		transition-duration: var(--duration-medium);
	}

	input:checked + & {
		background-color: var(--color-background-inverse-primary);
		border-color: var(--color-background-inverse-primary);

		&::after {
			scale: 1;
			opacity: 1;
		}
	}
}
