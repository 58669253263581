.o-roomle-plan {
	margin-inline: auto;
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	padding-inline: var(--margin-inline-body);
}
.o-roomle-configurator__container + .o-roomle-plan {
	margin-block-start: var(--space-x-large);
}
.o-layouts__row vi-form + .o-roomle-plan {
	margin-block-start: var(--_margin-block-start);
}
.o-layouts__column > .o-roomle-plan {
	padding-inline: 0;
}


.o-roomle-plan__sum {
	margin-block-start: var(--gap-small);
	font-size: var(--font-size-paragraph-large);
}
