.m-navigation {
	display: flex;
	align-items: center;

	@media print {
		display: none;
	}
}

.m-navigation__container {
	&:focus-visible {
		outline: none;
	}

	@media (min-width: 61em) {
		/* No offscreen navigation */
		display: contents;
	}
	@media not (min-width: 61em) {
		/* Offscreen navigation */
		position: fixed;
		inset: 0;
		inset-block-start: calc(var(--block-size-header) + var(--block-size-announcement, 0));
		inline-size: 100%;
		background-color: var(--color-background-primary);
		overflow: auto;
		display: none;

		&[data-open] {
			display: block;
		}
	}
}

.m-navigation__list {
	list-style: none;
	display: flex;

	@media not (min-width: 61em) {
		min-block-size: 100%;
		flex-direction: column;
		padding-block-start: var(--gap-large);
	}
	@media (min-width: 61em) {
		/* No offscreen navigation */
		flex-wrap: wrap;

		> li {
			display: flex;
		}
	}
	@media (min-width: 77em) {
		margin-inline-end: var(--gap-x-small);
	}
}

.m-navigation .a-button {
	@media (min-width: 61em) {
		/* No offscreen navigation */
		&[data-name="hamburger"] {
			display: none;
		}
	}

	@media not (min-width: 61em) {
		/* Offscreen navigation */
		&[data-name="hamburger"] {
			order: 2;
		}
		&[data-name="switch-language"] {
			display: none;
		}
	}
}

.m-navigation__switch-language {
	--_padding-inline: var(--gap-xx-large);
	margin-block-start: auto;

	> strong {
		display: flex;
		column-gap: var(--gap-xx-small);
		padding-inline: var(--_padding-inline);
		font: var(--font-label-small);
		color: var(--color-content-secondary)
	}

	> ul {
		display: flex;
		column-gap: var(--gap-medium);
		list-style: none;
		margin-block-start: calc(var(--gap-large) * -1 + var(--gap-small));
		padding-block: var(--gap-large);
		padding-inline: var(--_padding-inline);
		overflow-x: auto;
		scroll-padding-inline-end: var(--_padding-inline);
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		> li {
			flex-shrink: 0;
		}
	}

	a {
		&[aria-current="page"] {
			color: var(--color-content-tertiary);
		}
	}

	@media not (min-width: 61em) {
		padding-block-start: var(--gap-medium);
	}
	@media (min-width: 61em) {
		display: none !important;
	}
}
