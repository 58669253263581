.m-drawer-wishlist {
	transition-duration: var(--duration-medium);

	&::backdrop {
		background-color: transparent;
	}

	&:focus-visible {
		outline: none;
	}
}

.m-drawer-wishlist__backdrop {
	position: fixed;
	inset: 0;
	pointer-events: none; /* Allows clicks to pass through */
	z-index: 10; /* Ensure it's below the dialog */
	background-color: transparent;
	transition-property: background-color;
	transition-duration: inherit;

	.m-drawer-wishlist[data-open] & {
		background-color: var(--color-backdrop);
		transition-duration: var(--duration-x-large);
	}
}

.m-drawer-wishlist__container {
	pointer-events: auto;
	margin: 0;
	display: grid;
	margin-inline-start: auto;
	block-size: 100%;
	max-block-size: none;
	overflow: auto;
	padding-inline: var(--margin-inline-body);
	background-color: var(--color-background-primary);
	transition-duration: inherit;

	> * {
		grid-column: 1 / -1;
		grid-row: 1 / -1;
	}

	.m-drawer-wishlist[data-open] & {
		transition-duration: var(--duration-large);
		transition-timing-function: var(--ease-out);
	}

	@media not (min-width: 61em) {
		clip-path: inset(0 0 100%);
		transition-property: clip-path;

		.m-drawer-wishlist[data-open] & {
			clip-path: inset(0);
		}
	}

	@media (min-width: 61em) {
		translate: 100% 0;
		max-inline-size: min(calc(792rem / 16), calc(100% - var(--space-large)));
		padding-inline: var(--gap-grid);
		transition-property: translate;

		.m-drawer-wishlist[data-open] & {
			translate: 0 0;
		}
	}
}

.m-drawer-wishlist__close-button {
	z-index: 1;
	align-self: start;
	justify-self: end;
	margin-block-start: var(--gap-medium);

	> .a-button {
		margin-inline-end: calc(var(--gap-x-small) * -1);
	}
}
