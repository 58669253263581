.m-products {
	container-name: m-products;
	container-type: inline-size;

	> h3 {
		margin-block-end: 10px;
		font-size: inherit;
		font: var(--font-heading-xx-small);
	}
	> ul + h3 {
		margin-block-start: 20px;
	}

	> ul {
		margin-block: 0;
		padding-inline: 0;
		list-style: none;
	}
}
