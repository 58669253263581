.m-announcement {
	--color-content: var(--color-content-inverse-primary);
	--color-background: var(--color-background-inverse-primary);

	position: relative;
	z-index: calc(var(--z-index-header) + 1);
	margin-block-end: calc(-8rem / 16);
	font: var(--font-label-small);
	text-align: center;
	text-wrap: balance;
	color: var(--color-content);
	background-color: var(--color-background);
	background-image: var(--background-image);
	background-size: cover;
	display: flex;
	justify-content: center;
	overflow: hidden;

	> a,
	> span {
		display: inline-block;
		padding-inline: var(--gap-small);
		padding-block: calc(9rem / 16);
		padding-block-start: calc(11rem / 16); /* Fix vertical alignment */
		text-decoration: none;
		gap: var(--gap-xx-small);

		svg {
			block-size: 1lh;
			inline-size: 1lh;
			display: inline-block;
			vertical-align: middle;
			margin-block-start: calc(-2rem / 16);

			* {
				fill: currentcolor;
			}
		}

		polygon {
			transition-property: translate, scale;
			transition-duration: var(--duration-small);
		}
	}

	@media (pointer: fine) {
		> a:hover  {
			polygon:first-child {
				translate: 2px 0;
			}
			polygon:last-child {
				scale: 105% 1;
			}
		}
	}
}
