.m-figure {
	> img {
		inline-size: 100%;
	}
	> iframe {
		--aspect-ratio: 16 / 9;

		inline-size: 100%;
		aspect-ratio: var(--aspect-ratio);
	}
	> figcaption {
		color: var(--color-content-secondary);
		margin-block-start: var(--gap-x-small);
	}
}
:where(.a-display, .a-heading, .m-figure, .a-text) + .m-figure {
	margin-block-start: var(--gap-large);
}
