.m-grid {
	--_columns: 12;
	--_gap: var(--space-small);

	display: grid;
	grid-template-columns: minmax(10px, 1fr);
	gap: var(--_gap);

	&[data-align="start"] {
		align-items: start;
	}
	&[data-align="center"] {
		align-items: center;
	}
	&[data-align="end"] {
		align-items: end;
	}
	&[data-align="stretch"] {
		align-items: stretch;
	}

	&[data-justify="start"] {
		justify-items: start;
	}
	&[data-justify="center"] {
		justify-items: center;
	}
	&[data-justify="end"] {
		justify-items: end;
	}
	&[data-justify="stretch"] {
		justify-items: stretch;
	}

	&[data-centered] {
		box-sizing: content-box;
		max-inline-size: var(--inline-size-content);
		margin-inline: auto;
	}

	&[data-margin] {
		padding-inline: var(--margin-inline-body);
	}

	@media (min-width: 61em) {
		--_columns: 12;
		--_gap: var(--gap-grid);
		grid-template-columns: repeat(var(--_columns), 1fr);


		> * {
			--_span: var(--span);

			grid-column-end: span var(--_span);
		}
	}
}

.o-layouts + .m-grid {
	margin-block-start: var(--space-large);
}
