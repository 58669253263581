.o-systems {
	--_gap: var(--gap-large);
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	margin-inline: auto;
	padding-inline: var(--margin-inline-body);

	> ul {
		display: grid;
		gap: var(--_gap);
		grid-template-columns: repeat(auto-fill, minmax(calc(240rem / 16), 1fr));
		list-style: none;
	}

	@media (min-width: 61em) {
		--_gap: var(--gap-grid);

		> ul {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}
