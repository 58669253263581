.o-checkout {
	--_gap: var(--gap-large);

	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	inline-size: 100%;
	margin-block-start: var(--space-x-small);
	margin-inline: auto;
	padding-inline: var(--margin-inline-body);
	display: grid;
	grid-template-columns: minmax(10px, 1fr);
	gap: var(--_gap);
	align-items: start;
	grid-auto-flow: dense;

	@media (min-width: 61em) {
		--_gap: var(--gap-grid);

		margin-block-start: var(--space-large);
		grid-template-columns: repeat(12, 1fr);

		> * {
			--_span: var(--span, 12);

			grid-column-end: span var(--_span);
		}
	}
}

.o-checkout__form {
	@media (min-width: 61em) {
		align-self: stretch;
		grid-column-end: span 7;
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}
}

.o-checkout__cart {
	@media not (min-width: 61em) {
		/* Required for sticky vi-cart .footer */
		display: contents;
	}
	@media (min-width: 61em) {
		grid-column: 8 / span 5;
		grid-row-end: span 1;
		align-self: stretch;
		padding-inline-start: var(--gap-grid);

		> h2 {
			margin-block-end: var(--gap-small);
		}
	}
}

/* Below Headline. E.g. “Bezahlung wurde abgebrochen” */
.o-checkout__message {
	@media (min-width: 61em) {
		display: grid;
		grid-template-columns: subgrid;
		> * {
			grid-column: 1 / span 7;
		}
	}
}
