.a-icon {
	--_size: var(--line-height-label-medium);

	> svg {
		inline-size: var(--_size);
		block-size: var(--_size);
	}

	> svg * {
		fill: currentcolor;
	}

	&[data-kind="secondary"] {
		color: var(--color-content-secondary);
	}

	&[data-size="x-small"] {
		--_size: calc(14rem / 16);
	}
	&[data-size="small"] {
		--_size: var(--line-height-label-small);
	}
	&[data-size="large"] {
		--_size: var(--line-height-label-large);
	}
	&[data-size="x-large"] {
		--_size: var(--line-height-label-x-large);
	}
	&[data-size="xx-large"] {
		--_size: var(--line-height-heading-medium);
	}
	&[data-size="xxx-large"] {
		--_size: var(--line-height-heading-x-large);
	}

	&[data-kind="loader"] {
		animation-name: rotate;
		animation-duration: var(--duration-rotate-loader);
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}

	/* Special cases */
	&:where([data-variant="wishlist"], [data-variant="cart"]) {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			inset-inline-start: calc(50% + (3 / 20 * 100%));
			inset-block-end: calc(50% + (2 / 20 * 100%));
			inline-size: calc(8 / 20 * 100%);
			block-size: calc(8 / 20 * 100%);
			border-radius: 50%;
			background-color: currentcolor;
			scale: 0;
			transition-property: scale;
			transition-duration: var(--duration-medium);
		}

		.a-button[data-has-items] &,
		&[data-has-items] {
			&::before {
				scale: 1;
			}
		}
	}

	&[data-inverse] {
		color: var(--color-content-inverse-primary);
		&[data-kind="secondary"] {
			color: var(--color-content-inverse-secondary);
		}
	}
}
