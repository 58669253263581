.o-projects {
}

.o-projects__feature {
	margin-block-start: calc(var(--space-x-small));

	@media (min-width: 61em) {
		margin-block-start: calc(var(--space-large));
	}
}

.o-projects__list {
	margin-inline: auto;
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	padding-inline: var(--margin-inline-body);
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(calc(240rem / 16), 1fr));
	column-gap: var(--margin-inline-body);
	row-gap: var(--gap-grid);
	list-style: none;

	> li:first-of-type {
		grid-column: 1 / -1;
	}

	@media (min-width: 50em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (min-width: 61em) {
		column-gap: var(--gap-grid);
	}
}
