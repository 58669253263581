.m-details {
	border-block-end: var(--size-border) solid var(--color-border-opaque);
}

.m-details > summary {
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	padding-block: var(--gap-small);
	font: var(--font-label-large);
	transition-property: background-color;
	transition-duration: var(--duration-medium);

	.a-icon {
		content: '';
		display: block;
		block-size: var(--size-icon-large);
		inline-size: var(--size-icon-large);
		mask-image: url(/assets/images/icons/arrow-drop-down.svg);
		mask-size: 100%;
		rotate: 0deg;
		background-color: currentcolor;
		transition-property: rotate;
		transition-duration: var(--duration-medium);
	}

	&::marker,
	&::-webkit-details-marker {
		display: none;
	}

	.m-details:not([open]) &:where(:focus-visible, :hover) {
		background-color: var(--color-background-secondary);
	}

	.m-details[open] & {
		.a-icon {
			rotate: -180deg;
		}
	}
}

.m-details__content {
	padding-block-start: var(--gap-x-small);
	padding-block-end: var(--gap-xx-large);

	.m-details[open] & {
		animation: appearDown var(--duration-medium) ease-in-out;
	}
}
