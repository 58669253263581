.o-search {
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	inline-size: 100%;
	margin-inline: auto;
	margin-block-start: var(--space-small);
	padding-inline: var(--margin-inline-body);

	@media (min-width: 50em) {
		margin-block-start: var(--space-medium);
	}
	@media (min-width: 61em) {
		margin-block-start: var(--space-large);
	}
}

.o-search__form {
	display: flex;
	flex-direction: column;
	max-inline-size: var(--inline-size-text);
	margin-block-start: var(--space-small);
	row-gap: var(--gap-small);

	@media (min-width: 61em) {
		margin-block-start: var(--space-medium);
	}
}


.o-search__results {
	max-inline-size: var(--inline-size-text);
	margin-block-start: var(--space-small);
}
