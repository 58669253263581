.m-toasts {
	position: fixed;
	z-index: var(--z-index-toasts);
	inset-block-end: 0;
	inset-inline: 0;
	padding-block: var(--gap-small);
	padding-inline-end: var(--gap-small);
	display: flex;
	flex-direction: column-reverse;
	align-items: end;
	row-gap: var(--gap-small);
	pointer-events: none;

	> * {
		pointer-events: auto;
	}

	@media not (min-width: 61em) {
		inset-block-start: 0;
		inset-block-end: auto;
	}
}
