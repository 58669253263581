/* Typography */
.a-label {
	font: var(--font-label-medium);
	margin-block-end: var(--gap-medium);

	&[data-size="x-large"] {
		font: var(--font-label-x-large);
	}
	&[data-size="large"] {
		font: var(--font-label-large);
	}
	&[data-size="small"] {
		font: var(--font-label-small);
	}
	&[data-size="x-small"] {
		font: var(--font-label-x-small);
	}
}
