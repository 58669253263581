.m-filter {
	--_label-max-size: var(--label-max-size, auto);
	--_column-gap: var(--gap-large);

	display: grid;
	row-gap: var(--gap-x-small);
	column-gap: var(--_column-gap);

	&[data-variant="inline-grid"] {
		display: grid;
		grid-template-columns: minmax(min-content, var(--_label-max-size)) 1fr;
		row-gap: 0;
		align-items: start;
	}

	@media not (min-width: 61em) {
		overflow: auto;
		margin-inline: calc(var(--margin-inline-body) * -1);
		padding-inline: var(--margin-inline-body);
		scroll-padding-inline: var(--margin-inline-body);
		scrollbar-width: none;
		grid-template-columns: auto 1fr;

		&::-webkit-scrollbar {
			display: none;
		}

		> * {
			flex-shrink: 0;
		}

		&[data-variant="inline-grid"] {
			--_column-gap: var(--gap-small);
		}
	}
}
.o-downloads__filter > .m-filter + .m-filter {
	/* Fix for vertical rhythm */
	margin-block-start: calc(var(--gap-xx-small) * -1);
}

.m-filter__label {
	font: var(--font-label-medium);
	color: var(--color-content-secondary);
	white-space: nowrap;

	&[data-size="small"] {
		font: var(--font-label-small);
	}

	.m-filter[data-variant="inline-grid"] & {
		margin-block-start: calc(8rem / 16);
		/* font: var(--font-label-small); */
	}
}

.m-filter__list {
	display: flex;
	list-style: none;
	column-gap: var(--gap-xxx-small);
	row-gap: var(--gap-xx-small);

	&[data-gap="small"] {
		column-gap: var(--gap-x-small);
		row-gap: var(--gap-small);
	}

	> * {
		flex-shrink: 0;
	}

	@media (min-width: 61em) {
		flex-wrap: wrap;
	}
}
