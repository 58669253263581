.a-pagination {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: baseline;
	gap: var(--gap-xx-small);
	font: var(--font-label-small);
	color: var(--color-content-secondary);
}

.a-pagination__seperator {
	--_padding-inline: var(--gap-x-small);
	min-inline-size: calc(1lh + var(--_padding-inline) * 2);
	text-align: center;
}
