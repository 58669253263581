.m-card {
	--_columns: 12;
	--_gap: var(--gap-grid);
	--_padding-inline: 0;

	max-inline-size: var(--inline-size-content);
	display: block;
	container-type: inline-size;
	text-decoration: none;
}

.m-card__container {
	@container (min-width: 668px) {
		.m-card[data-size="large"] & {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			gap: var(--_gap);
			align-items: end;
		}
	}
}

.m-card__image {
	aspect-ratio: var(--aspect-ratio);
	inline-size: 100%;

	@container (min-width: 668px) {
		.m-card[data-size="large"] & {
			align-self: start;
			grid-column-end: span 7;
		}
	}
	@container (min-width: 992px) {
		.m-card[data-variant="project"] & {
			grid-column-end: span 8;
		}
	}
}

.m-card__text {
	display: flex;
	flex-direction: column;
	row-gap: var(--gap-small);
	align-items: start;
	margin-block-start: var(--gap-large);

	.m-card[data-size="large"] & {
		order: 1;
	}
	.m-card[data-variant="project"] & {
		padding-block-end: 0;
	}

	@container (min-width: 668px) {
		.m-card[data-size="large"] & {
			grid-column-end: span 5;
			margin-block-start: 0;
			padding-block-end: var(--gap-xxx-large);
		}
	}
	@container (min-width: 992px) {
		.m-card[data-variant="project"] & {
			grid-column-end: span 4;
		}
	}
}

.m-card__title {
	display: block;
	font: var(--font-heading-medium);
	text-wrap: balance;

	@container (min-width: 668px) {
		.m-card[data-size="large"] & {
			font: var(--font-display-small);
		}
	}
}

.m-card__teaser {
	@media not (min-width: 33em) {
		.m-card[data-variant="project"] & {
			display: none;
		}
	}
}
