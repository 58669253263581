.o-lightbox {
	--_padding: var(--margin-inline-body);
	--_padding-box: calc(48px + var(--_padding) * 2);
	--_max-inline-size: calc(100vw - var(--_padding) * 2);
	--_max-block-size: calc(100vh - var(--_padding) * 2);

	box-sizing: border-box;
	inline-size: 100%;
	padding: 0 var(--_padding-box);
	background-color: transparent;
	border: 0;
	max-block-size: var(--_max-block-size);
	max-inline-size: var(--_max-inline-size);

	> img {
		margin-inline: auto;
		inline-size: auto;
		block-size: auto;
		max-inline-size: min(var(--_max-inline-size), 100%);
		max-block-size: var(--_max-block-size);
		object-fit: contain;
		background-color: var(--color-white);
	}

	> form {
		position: absolute;
		top: 0.5em;
		right: 0.5em;
	}

	&::backdrop {
		background-color: var(--color-backdrop);
	}

	&:focus-visible {
		outline: none;
	}

	@media not (min-width: 600px) {
		padding: var(--_padding-box) 0;
		max-inline-size: none;

		> img {
			max-inline-size: none;
			inline-size: 100%;
		}

		> form {
			left: calc(50% - 48px * 0.5);
			bottom: 0.5em;
			top: auto;
		}
	}
	@media (min-width: 600px) {

		> form {
			right: 1em;
		}
	}
}
