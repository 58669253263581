:root {
	/* Non offscreen navigation: 60em */

	/* 40 * 11 + 68 * 12 = 1256 */

	--inline-size-content: calc(1208rem / 16);
	--inline-size-text: calc(896rem / 16);
	--tap-target: calc(44rem / 16);
	--block-size-header: calc(64rem / 16);

	--gap-xxx-small: calc(2rem / 16);
	--gap-xx-small: calc(4rem / 16);
	--gap-x-small: calc(8rem / 16);
	--gap-small: calc(12rem / 16);
	--gap-medium: calc(16rem / 16);
	--gap-large: calc(20rem / 16);
	--gap-x-large: calc(24rem / 16);
	--gap-xx-large: calc(28rem / 16);
	--gap-xxx-large: calc(32rem / 16);

	--space-x-small: calc(20rem / 16);
	--space-small: calc(32rem / 16);
	--space-medium: calc(40rem / 16);
	--space-large: calc(68rem / 16);
	--space-x-large: calc(88rem / 16);
	--space-xx-large: calc(128rem / 16);
	--space-xxx-large: calc(200rem / 16);

	--gap-grid: var(--space-medium);

	--column: calc((var(--inline-size-content) - var(--gap-grid) * 11) / 12);

	--size-border: calc(2rem / 16);

	--size-icon-x-small: var(--line-height-label-x-small);
	--size-icon-small: var(--line-height-label-small);
	--size-icon-medium: var(--line-height-label-medium);
	--size-icon-large: var(--line-height-label-large);
	--size-icon-x-large: var(--line-height-label-x-large);
	--size-icon-xx-large: var(--line-height-heading-medium);
	--size-icon-xxx-large: var(--line-height-heading-large);

	--margin-inline-body: var(--gap-medium);

	--corner-radius-button: calc(2rem / 16);

	--z-index-header: 100;
	--z-index-dialog: 200;
	--z-index-toasts: 300;
	--z-index-tooltip: 400;

	@media (min-width: 61em) {
		--margin-inline-body: var(--gap-large);
		--block-size-header: calc(72rem / 16);
	}
}
