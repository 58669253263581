/* Styling for light dom of vi-carousel */

vi-carousel > ul  {
	margin-block: 0;
	padding-inline: 0;
	display: flex;
	overflow: auto;
	list-style: none;
	scroll-snap-type: x mandatory;
	scrollbar-width: none;
	cursor: grab;

	> * {
		flex-basis: 100%;
		flex-shrink: 0;
		scroll-snap-align: start;
	}

	&::-webkit-scrollbar {
		display: none;
	}

	@media (prefers-reduced-motion: no-preference) {
		scroll-behavior: smooth;
	}
}
:where(.a-display, .a-heading, vi-carousel) + vi-carousel {
	margin-block-start: var(--gap-large);
}

/* Fix base alignment when text block(s) are next to vi-carousel. 44px is the height of pagination of vi-carousel */
.m-grid[data-align="end"]:has(vi-carousel:last-child) > .o-layouts__column:not(:has(vi-carousel:last-child)) {
	@media (min-width: 61em) {
		padding-block-end: calc(44rem / 16);
	}
}
