.a-field {
	--_label-max-size: var(--label-max-size, calc(72rem/ 10));
	--_color-label: var(--color-content-secondary);
	--_font-label: var(--font-label-medium);

	inline-size: 100%;
	display: grid;
	gap: var(--gap-x-small);

	&[data-variant="checkbox"] {
		--_color-label: var(--color-content-primary);

		display: grid;
		align-items: start;
		grid-template-columns: min-content 1fr;
		column-gap: var(--gap-small);
		cursor: pointer;
	}
	&[data-variant="inline-grid"] {
		display: grid;
		grid-template-columns: minmax(min-content, var(--_label-max-size)) 1fr;
		column-gap: var(--gap-large);
		row-gap: 0;
		align-items: start;
	}
	&[data-variant="inline-grid-filter"] {
		--_font-label: var(--font-label-small);

		display: grid;
		grid-template-columns: minmax(min-content, var(--_label-max-size)) 1fr;
		column-gap: var(--gap-large);
		row-gap: 0;
		align-items: center;
	}

	/* Use :has as soon Firefox ESR supports :has() */
	&:where([data-feature="char-count"]) {
		grid-template-columns: auto auto;

		> * {
			grid-column-end: span 2;
		}
	}

	@media not (min-width: 61em) {
		&[data-variant="inline-grid-filter"] {
			grid-template-columns: auto 1fr;
		}
	}

	@media print {
		display: block;
	}
}

.a-field__label,
.a-field > label {
	font: var(--_font-label);
	color: var(--_color-label);

	/* Use :has as soon Firefox ESR supports :has() */
	.a-field[data-feature="char-count"] & {
		grid-column-end: span 1;
	}
}

.a-field__char-count {
	grid-column-end: span 1;
	text-align: end;
	color: var(--color-content-secondary);

	@media print {
		display: none;
	}
}

.a-field__required {
	text-decoration: none;
}

.a-field__error {
	color: var(--color-negative);
}
