/* Typography */
.a-heading {
	display: block;
	max-inline-size: var(--inline-size-text);
	font: var(--font-heading-medium);
	text-wrap: balance;

	> small {
		font: inherit;
		font-weight: 400;
	}

	&[data-size="xx-large"] {
		font: var(--font-heading-xx-large);
	}
	&[data-size="x-large"] {
		font: var(--font-heading-x-large);
	}
	&[data-size="large"] {
		font: var(--font-heading-large);
	}
	&[data-size="medium"] {
		font: var(--font-heading-medium);
	}
	&[data-size="small"], &:where(h3) {
		font: var(--font-heading-small);
	}
	&[data-size="x-small"], &:where(h4) {
		font: var(--font-heading-x-small);
	}
	&[data-size="xx-small"], &:where(h5) {
		font: var(--font-heading-xx-small);
	}
}

.a-heading + .a-heading {
	margin-block-start: var(--gap-small);
}
:where(.a-display, .a-heading, .a-text, .m-figure, .m-stack, vi-form) + .a-heading {
	margin-block-start: var(--gap-grid);
}
