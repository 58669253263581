:root {
	/* Color Primitives */
	--color-white:    hsla(0, 0%, 100%, 1);
	--color-gray-10:  hsla(0, 0%, 99%, 1);
	--color-gray-50:  hsla(0, 0%, 93%, 1);
	--color-gray-100: hsla(0, 0%, 90%, 1);
	--color-gray-150: hsla(0, 0%, 85%, 1);
	--color-gray-200: hsla(0, 0%, 80%, 1);
	--color-gray-300: hsla(0, 0%, 70%, 1);
	--color-gray-400: hsla(0, 0%, 58%, 1);
	--color-gray-500: hsla(0, 0%, 48%, 1);
	--color-gray-600: hsla(0, 0%, 38%, 1);
	--color-gray-700: hsla(0, 0%, 26%, 1);
	--color-gray-800: hsla(0, 0%, 18%, 1);
	--color-gray-900: hsla(0, 0%, 8%, 1);
	--color-gray-950: hsla(0, 0%, 7%, 1);
	--color-black:    hsla(0, 0%, 1%, 1);

	--color-red-50:  hsla(360, 100%, 95%, 1);
	--color-red-100: hsla(360, 100%, 90%, 1);
	--color-red-200: hsla(360, 100%, 80%, 1);
	--color-red-300: hsla(360, 100%, 70%, 1);
	--color-red-400: hsla(360, 100%, 60%, 1);
	--color-red-500: hsla(360, 100%, 50%, 1);
	--color-red-600: hsla(360, 100%, 40%, 1);
	--color-red-700: hsla(360, 100%, 30%, 1);
	--color-red-800: hsla(360, 100%, 20%, 1);
	--color-red-900: hsla(360, 100%, 10%, 1);

	--color-yellow-50:  hsla(55, 100%, 95%, 1);
	--color-yellow-100: hsla(55, 100%, 90%, 1);
	--color-yellow-200: hsla(55, 100%, 80%, 1);
	--color-yellow-300: hsla(55, 100%, 70%, 1);
	--color-yellow-400: hsla(55, 100%, 60%, 1);
	--color-yellow-500: hsla(55, 100%, 50%, 1);
	--color-yellow-600: hsla(55, 100%, 40%, 1);
	--color-yellow-700: hsla(55, 100%, 30%, 1);
	--color-yellow-800: hsla(55, 100%, 20%, 1);
	--color-yellow-900: hsla(55, 100%, 10%, 1);

	--color-green-50:  hsla(85, 50%, 95%, 1);
	--color-green-100: hsla(85, 45%, 90%, 1);
	--color-green-200: hsla(85, 69%, 80%, 1);
	--color-green-300: hsla(85, 69%, 70%, 1);
	--color-green-400: hsla(85, 69%, 60%, 1);
	--color-green-500: hsla(85, 69%, 50%, 1);
	--color-green-600: hsla(85, 69%, 40%, 1);
	--color-green-700: hsla(85, 69%, 28%, 1);
	--color-green-800: hsla(85, 69%, 20%, 1);
	--color-green-900: hsla(85, 69%, 10%, 1);

	/* Foundation Colors */
	--color-primary-a: var(--color-black);
	--color-primary-b: var(--color-white);
	--color-negative: var(--color-red-400);
	--color-warning: var(--color-yellow-400);
	--color-positive: var(--color-green-400);

		/* Core Colors */
	--color-background-primary: var(--color-primary-b);
	--color-background-secondary: var(--color-gray-50);
	--color-background-tertiary: var(--color-gray-100);
	--color-background-inverse-primary: var(--color-primary-a);
	--color-background-inverse-secondary: var(--color-gray-800);

	--color-content-primary: var(--color-primary-a);
	--color-content-secondary: var(--color-gray-600);
	--color-content-tertiary: var(--color-gray-400);
	--color-content-inverse-primary: var(--color-white);
	--color-content-inverse-secondary: var(--color-gray-300);
	--color-content-inverse-tertiary: var(--color-gray-400);

	--color-border-opaque: var(--color-gray-200);
	--color-border-transparent: color-mix(in hsl, var(--color-primary-a) 8%, transparent);
	--color-border-selected: var(--color-content-primary);
	--color-border-inverse-opaque: var(--color-gray-800);
	--color-border-inverse-transparent: color-mix(in hsl, var(--color-primary-b) 8%, transparent);
	--color-border-inverse-selected: var(--color-primary-b);

	/* Core Colors */
	--color-background-negative: var(--color-red-600);
	--color-background-warning: hsla(45, 90%, 66%, 1);
	--color-background-positive: var(--color-green-700);
	--color-background-light-negative: var(--color-red-100);
	--color-background-light-warning: var(--color-yellow-100);
	--color-background-light-positive: var(--color-green-100);

	--opacity-secondary: 0.6;
	--opacity-tertiary: 0.5;

	--color-backdrop: color-mix(in hsl, var(--color-black) 90%, transparent);
}

body[data-inverse] {
	--color-backdrop: color-mix(in hsl, var(--color-gray-950) 90%, transparent);
}


/**
 * Set color for background
 * see https://stackoverflow.com/a/63322762/1970347
 */
::backdrop {
	--color-backdrop: color-mix(in hsl, black 90%, transparent);
	background-color: transparent;
}
