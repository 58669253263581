/*
We declare all variables here as default so that they are easy to override...
*/
/* Border, shadows, ... */
/*
Variables that begin with $var- should NEVER be used directly in CSS rules.
they should only be included via the "@include var(property, var-name)"
mechanism.
*/
/* Border styles */
/* Cookie notice positioning */
/* Text colors */
/* Font Families */
/* White */
/* Differently shaded colors */
/*
Use @include var(property, varname) to include a variable, e.g.

		@include var(background-color, white1);
to create a white background.
*/

.klaro {
	--font-size: var(--font-size-paragraph-small);
	--dark1: var(--color-background-inverse-primary);
	--border-radius: 0;
}


/* Spacing */
/* Breakpoints */
.klaro {
	font: var(--font-paragraph-small);
}
.klaro button {
	font-family: inherit;
	font-family: var(--font-family, inherit);
	font-size: 14px;
	font-size: var(--font-size, 14px);
}
.klaro.cm-as-context-notice {
	box-sizing: border-box;
	height: 100%;
}
.klaro .cookie-modal,
.klaro .context-notice,
.klaro .cookie-notice {
	/* The switch - the box around the slider */
}
.klaro .cookie-modal .cm-switch-container,
.klaro .context-notice .cm-switch-container,
.klaro .cookie-notice .cm-switch-container {
	border-bottom-style: solid;
	border-bottom-style: var(--border-style, solid);
	border-bottom-width: 1px;
	border-bottom-width: var(--border-width, 1px);
	border-bottom-color: #c8c8c8;
	border-bottom-color: var(--light2, #c8c8c8);
	display: block;
	position: relative;
	padding: 10px;
	padding-left: 66px;
	line-height: 20px;
	vertical-align: middle;
	min-height: 40px;
}
.klaro .cookie-modal .cm-switch-container:last-child,
.klaro .context-notice .cm-switch-container:last-child,
.klaro .cookie-notice .cm-switch-container:last-child {
	border-bottom: 0;
}
.klaro .cookie-modal .cm-switch-container:first-child,
.klaro .context-notice .cm-switch-container:first-child,
.klaro .cookie-notice .cm-switch-container:first-child {
	margin-top: 0;
}
.klaro .cookie-modal .cm-switch-container p,
.klaro .context-notice .cm-switch-container p,
.klaro .cookie-notice .cm-switch-container p {
	margin-top: 0;
}
.klaro .cookie-modal .cm-switch,
.klaro .context-notice .cm-switch,
.klaro .cookie-notice .cm-switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 30px;
}
/* .klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider {
	background-color: #1a936f;
	background-color: var(--green1, #1a936f);
} */
.klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider {
}
.klaro .cookie-modal .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.half-checked:checked + .cm-list-label .slider::before {
	-ms-transform: translateX(10px);
	transform: translateX(10px);
}
.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider {
	background-color: #24cc9a;
	background-color: var(--color-background-primary, #24cc9a);
	opacity: 1;
}
.klaro .cookie-modal .cm-list-input.only-required + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input.only-required + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.only-required + .cm-list-label .slider::before {
	-ms-transform: translateX(10px);
	transform: translateX(10px);
}
.klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .context-notice .cm-list-input.required:checked + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider {
	opacity: 1;
	cursor: not-allowed;
}
.klaro .cookie-modal .slider,
.klaro .context-notice .slider,
.klaro .cookie-notice .slider {
	/* box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19); */
	box-shadow: 0 0 0 var(--size-border) var(--color-content-tertiary);
}
.klaro .cookie-modal .cm-list-input,
.klaro .context-notice .cm-list-input,
.klaro .cookie-notice .cm-list-input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 50px;
	height: 30px;
}
.klaro .cookie-modal .cm-list-title,
.klaro .context-notice .cm-list-title,
.klaro .cookie-notice .cm-list-title {
	font: var(--font-label-small);
}
.klaro .cookie-modal .cm-list-description,
.klaro .context-notice .cm-list-description,
.klaro .cookie-notice .cm-list-description {
	color: var(--color-content-secondary);
	font: var(--font-paragraph-x-small);
	font-weight: 500;
	padding-top: 4px;
}
.klaro .cookie-modal .cm-list-label,
.klaro .context-notice .cm-list-label,
.klaro .cookie-notice .cm-list-label {
	/* The slider */
	/* Rounded sliders */
}
.klaro .cookie-modal .cm-list-label .cm-switch,
.klaro .context-notice .cm-list-label .cm-switch,
.klaro .cookie-notice .cm-list-label .cm-switch {
	position: absolute;
	left: 0;
}
.klaro .cookie-modal .cm-list-label .slider,
.klaro .context-notice .cm-list-label .slider,
.klaro .cookie-notice .cm-list-label .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: var(--duration-small);
	width: 50px;
	display: inline-block;
}
.klaro .cookie-modal .cm-list-label .slider::before,
.klaro .context-notice .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-label .slider::before {
	background-color: #e6e6e6;
	background-color: var(--color-content-primary, #e6e6e6);
	position: absolute;
	content: "";
	height: 20px;
	width: 20px;
	left: 5px;
	bottom: 5px;
	transition: var(--duration-small);
}
.klaro .cookie-modal .cm-list-label .slider.round,
.klaro .context-notice .cm-list-label .slider.round,
.klaro .cookie-notice .cm-list-label .slider.round {
	border-radius: 30px;
}
.klaro .cookie-modal .cm-list-label .slider.round::before,
.klaro .context-notice .cm-list-label .slider.round::before,
.klaro .cookie-notice .cm-list-label .slider.round::before {
	border-radius: 50%;
}
.klaro .cookie-modal .cm-list-label input:focus + .slider,
.klaro .context-notice .cm-list-label input:focus + .slider,
.klaro .cookie-notice .cm-list-label input:focus + .slider {
	/* box-shadow-color: #48dfb2; */
	/* box-shadow-color: var(--green3, #48dfb2); */
	/* box-shadow: 0 0 1px var(--box-shadow-color, green3); */
}
.klaro .cookie-modal .cm-list-label input:checked + .slider::before,
.klaro .context-notice .cm-list-label input:checked + .slider::before,
.klaro .cookie-notice .cm-list-label input:checked + .slider::before {
	-ms-transform: translateX(20px);
	transform: translateX(20px);
}
.klaro .cookie-modal .cm-list-input:focus + .cm-list-label .slider,
.klaro .context-notice .cm-list-input:focus + .cm-list-label .slider,
.klaro .cookie-notice .cm-list-input:focus + .cm-list-label .slider {
	/* box-shadow: 0 4px 6px 0 rgba(125, 125, 125, 0.2), 5px 5px 10px 0 rgba(125, 125, 125, 0.19); */
}
.klaro .cookie-modal .cm-list-input:checked + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input:checked + .cm-list-label .slider::before {
	-ms-transform: translateX(20px);
	transform: translateX(20px);
}
.klaro .cookie-modal .cm-list-input.required:checked + .cm-list-label .slider::before,
.klaro .context-notice .cm-list-input.required:checked + .cm-list-label .slider::before,
.klaro .cookie-notice .cm-list-input.required:checked + .cm-list-label .slider::before {
	background-color: var(--color-content-tertiary);
}
.klaro .cookie-modal .slider,
.klaro .context-notice .slider,
.klaro .cookie-notice .slider {
	/* box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19); */
}
.klaro .cookie-modal a,
.klaro .context-notice a,
.klaro .cookie-notice a {
	color: currentcolor;
	text-decoration: underline;
}
.klaro .cookie-modal p,
.klaro .cookie-modal strong,
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal ul,
.klaro .cookie-modal li,
.klaro .context-notice p,
.klaro .context-notice strong,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice ul,
.klaro .context-notice li,
.klaro .cookie-notice p,
.klaro .cookie-notice strong,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice ul,
.klaro .cookie-notice li {
	color: var(--color-content-primary);
}
.klaro .cookie-modal p,
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal ul,
.klaro .cookie-modal li,
.klaro .context-notice p,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice ul,
.klaro .context-notice li,
.klaro .cookie-notice p,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice ul,
.klaro .cookie-notice li {
	display: block;
	text-align: left;
	margin: 0;
	padding: 0;
	margin-top: 0.7em;
}
.klaro .cookie-modal h1,
.klaro .cookie-modal h2,
.klaro .cookie-modal h3,
.klaro .cookie-modal h4,
.klaro .cookie-modal h5,
.klaro .cookie-modal h6,
.klaro .context-notice h1,
.klaro .context-notice h2,
.klaro .context-notice h3,
.klaro .context-notice h4,
.klaro .context-notice h5,
.klaro .context-notice h6,
.klaro .cookie-notice h1,
.klaro .cookie-notice h2,
.klaro .cookie-notice h3,
.klaro .cookie-notice h4,
.klaro .cookie-notice h5,
.klaro .cookie-notice h6 {
	font-family: inherit;
	font-family: var(--title-font-family, inherit);
}
.klaro .cookie-modal .cm-link,
.klaro .context-notice .cm-link,
.klaro .cookie-notice .cm-link {
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok a,
.klaro .cookie-modal .cm-btn,
.klaro .context-notice .cm-btn,
.klaro .cookie-notice .cm-btn {
	--_color-content: var(--color-content-inverse-primary);
	--_color-background: var(--color-background-inverse-primary);
	--_color-background-mix: var(--color-black);
	--_duration: var(--duration-medium);

	color: var(--_color-content);
	background-color: var(--_color-background);
	padding-block: var(--gap-x-small);
	padding-inline: var(--gap-small);
	border-radius: var(--corner-radius-button);
	border-style: none;
	font: var(--font-label-small);
	cursor: pointer;
	text-decoration: none;
	transition-property: background-color, color;
	transition-duration: var(--_duration);

	&:hover {
		background-color: color-mix(in hsl, var(--_color-background), var(--_color-background-mix) 15%);
	}
	&:active {
		background-color: color-mix(in hsl, var(--_color-background), var(--_color-background-mix) 20%);
		transition-duration: var(--duration-x-small);
	}
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok a,
.klaro .cookie-notice .cm-btn,
.klaro .cookie-modal .cm-btn,
.klaro .context-notice .cm-btn.cm-btn-success {
	--_color-content: var(--color-content-primary);
	--_color-background: var(--color-background-primary);
	--_color-background-mix: var(--color-gray-150);
}
.klaro .cookie-notice .cm-btn.cm-btn-success,
.klaro .cookie-modal .cm-btn.cm-btn-success,
.klaro .context-notice .cm-btn.cm-btn-info,
.klaro .cookie-notice .cm-btn.cm-btn-info {
	--_color-content: var(--color-content-inverse-primary);
	--_color-background: var(--color-background-inverse-primary);
	--_color-background-mix: var(--color-white);
}

/* Secondary button */
.klaro .cookie-modal .cm-btn.cm-btn-decline,
.klaro .cookie-modal .cm-btn.cm-btn-accept,
.klaro .cookie-modal .cm-btn.cm-btn-info {
	--_color-content: var(--color-content-primary);
	--_color-background: var(--color-background-tertiary);
	--_color-background-mix: var(--color-black);

	&:hover {
		background-color: color-mix(in hsl, var(--_color-background), var(--_color-background-mix) 4%) !important;
	}
	&:active {
		background-color: color-mix(in hsl, var(--_color-background), var(--_color-background-mix) 8%) !important;
	}
}
.klaro .cookie-modal .cm-btn:disabled,
.klaro .context-notice .cm-btn:disabled,
.klaro .cookie-notice .cm-btn:disabled {
	opacity: 0.5;
}
.klaro .cookie-modal .cm-btn.cm-btn-close,
.klaro .context-notice .cm-btn.cm-btn-close,
.klaro .cookie-notice .cm-btn.cm-btn-close {
	background-color: #c8c8c8;
	background-color: var(--light2, #c8c8c8);
}

.klaro .context-notice {
	background-color: var(--color-background-secondary);
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: var(--gap-small);
	padding: var(--margin-inline-body);
	box-sizing: border-box;
	height: 100%;
}
.cm-as-context-notice {
	[data-name="vimeo"] > & {
		aspect-ratio: 16 / 9;
	}
	[data-name="matterport"] > & {
		aspect-ratio: 16 / 10;
	}
}
.klaro .context-notice.cm-dark {
	background-color: #333;
	background-color: var(--dark1, #333);
	border-color: #5c5c5c;
	border-color: var(--color-content-inverse-tertiary, #5c5c5c);
}
.klaro .context-notice.cm-dark p {
	color: #fafafa;
	color: var(--light1, #fafafa);
}
.klaro .context-notice.cm-dark p a {
	color: #459cdc;
	color: var(--blue2, #459cdc);
}
.klaro .context-notice p {
	color: #333;
	color: var(--dark1, #333);
	flex-grow: 0;
	text-align: center;
	padding-top: 0;
	margin-top: 0;
}
.klaro .context-notice p a {
	color: #24cc9a;
	color: var(--green2, #24cc9a);
}
.klaro .context-notice p.cm-buttons {
	margin-top: var(--gap-small);
	display: flex;
	gap: var(--gap-x-small);
}
.klaro .context-notice p.ccn-description-empty-store {
	margin-top: 24px;
	font-size: 14px;
	font-size: var(--font-size, 14px);
}
.klaro .cookie-modal {
	width: 100%;
	height: 100%;
	position: fixed;
	overflow: hidden;
	left: 0;
	top: 0;
	z-index: 1000;
}
.klaro .cookie-modal.cm-embedded {
	position: relative;
	height: inherit;
	width: inherit;
	left: inherit;
	right: inherit;
	z-index: 0;
}
.klaro .cookie-modal.cm-embedded .cm-modal.cm-klaro {
	position: relative;
	-ms-transform: none;
			transform: none;
}
.klaro .cookie-modal .cm-bg {
	background: var(--color-backdrop);
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
}
.klaro .cookie-modal .cm-modal.cm-klaro {
	background-color: #333;
	background-color: var(--color-background-primary, #333);
	color: #fafafa;
	color: var(--color-content-primary, #fafafa);
	z-index: 1001;
	/* box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19); */
	width: 100%;
	max-height: 98%;
	top: 50%;
	-ms-transform: translateY(-50%);
			transform: translateY(-50%);
	position: fixed;
	overflow: auto;
}
@media (min-width: 660px) {
	.klaro .cookie-modal .cm-modal.cm-klaro {
		border-radius: 4px;
		border-radius: var(--border-radius, 4px);
		position: relative;
		margin: 0 auto;
		max-width: 640px;
		height: auto;
		width: auto;
	}
}
.klaro .cookie-modal .cm-modal .hide {
	border-style: none;
	background: none;
	cursor: pointer;
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 1;
}
.klaro .cookie-modal .cm-modal .hide svg {
	stroke: currentcolor;
}
.klaro .cookie-modal .cm-modal .cm-footer {
	border-top-color: #5c5c5c;
	border-top-color: var(--color-content-inverse-tertiary, #5c5c5c);
	border-top-width: 1px;
	border-top-width: var(--border-width, 1px);
	border-top-style: solid;
	border-top-style: var(--border-style, solid);
	padding: var(--margin-inline-body);
}
.klaro .cookie-modal .cm-modal .cm-footer-buttons {
	display: flex;
	flex-flow: row;
	gap: var(--gap-x-small);
	flex-wrap: wrap;
}
.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by {
	font-size:var(--font-label-x-small);
	padding-top: 4px;
	text-align: center;
}
.klaro .cookie-modal .cm-modal .cm-footer .cm-powered-by a {
	color: #5c5c5c;
	color: var(--color-content-tertiary, #5c5c5c);
}
.klaro .cookie-modal .cm-modal .cm-header {
	border-bottom-width: 1px;
	border-bottom-width: var(--border-width, 1px);
	border-bottom-style: solid;
	border-bottom-style: var(--border-style, solid);
	border-bottom-color: #5c5c5c;
	border-bottom-color: var(--color-content-inverse-tertiary, #5c5c5c);
	padding: var(--margin-inline-body);
	padding-right: 24px;
}
.klaro .cookie-modal .cm-modal .cm-header h1 {
	margin: 0;
	font: var(--font-heading-large);
	display: block;
}
.klaro .cookie-modal .cm-modal .cm-header h1.title {
	padding-right: 20px;
}
.klaro .cookie-modal .cm-modal .cm-body {
	padding: var(--margin-inline-body);
}
.klaro .cookie-modal .cm-modal .cm-body ul {
	display: block;
}
.klaro .cookie-modal .cm-modal .cm-body span {
	display: inline-block;
	width: auto;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes {
	padding: 0;
	margin: 0;
}
.klaro .cm-services {
	margin-top: var(--gap-xx-small);
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-caret,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-caret {
	color: var(--color-content-secondary);
	font: var(--font-label-x-small);
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content {
	margin-left: -40px;
	display: none;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose .cm-services .cm-content.expanded,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose .cm-services .cm-content.expanded {
	margin-top: var(--gap-medium);
	display: block;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose {
	position: relative;
	line-height: 20px;
	vertical-align: middle;
	padding-left: 60px;
	min-height: 40px;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service:first-child,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose:first-child {
	margin-top: 0;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p {
	margin-top: 0;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service p.purposes,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose p.purposes {
	color: var(--color-content-secondary);
	font: var(--font-label-x-small);
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service.cm-toggle-all,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose.cm-toggle-all {
	border-top-width: 1px;
	border-top-width: var(--border-width, 1px);
	border-top-style: solid;
	border-top-style: var(--border-style, solid);
	border-top-color: #5c5c5c;
	border-top-color: var(--color-content-inverse-tertiary, #5c5c5c);
	padding-top: 1em;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-list-title,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-list-title {
	font-weight: 700;
}
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-service span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-services li.cm-purpose span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-service span.cm-required,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-opt-out,
.klaro .cookie-modal .cm-modal .cm-body ul.cm-purposes li.cm-purpose span.cm-required {
	color: var(--color-content-secondary);
	padding-left: var(--gap-x-small);
	font: var(--font-label-x-small);
}
.klaro .cookie-notice:not(.cookie-modal-notice) {
	color: var(--color-content-primary);
	background-color: var(--color-background-secondary);
	z-index: 999;
	position: fixed;
	width: 100%;
	bottom: 0;
	right: 0;
}
@media (min-width: 680px) {
	.klaro .cookie-notice:not(.cookie-modal-notice) {
		border-radius: 4px;
		border-radius: var(--border-radius, 4px);
		position: fixed;
		position: var(--notice-position, fixed);
		right: 20px;
		right: var(--notice-right, 20px);
		left: auto;
		left: var(--notice-left, auto);
		bottom: 20px;
		bottom: var(--notice-bottom, 20px);
		top: auto;
		top: var(--notice-top, auto);
		max-width: calc(400rem / 16);
		/* box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19); */
	}
}
@media not (min-width: 680px) {
	.klaro .cookie-notice:not(.cookie-modal-notice) {
		border-style: none;
		border-radius: 0;
	}
}
.klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded {
	position: relative;
	height: inherit;
	width: inherit;
	left: inherit;
	right: inherit;
	bottom: inherit;
	z-index: 0;
}
.klaro .cookie-notice:not(.cookie-modal-notice).cn-embedded .cn-body {
	padding-top: 0.5em;
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body {
	margin-bottom: 0;
	margin-right: 0;
	bottom: 0;
	padding: var(--margin-inline-body);
	padding-top: 0;

	> #id-cookie-notice {
		margin-top: 0;
		padding-block: var(--margin-inline-body);
	}
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p {
	margin-bottom: 0.5em;
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body p.cn-changes {
	text-decoration: underline;
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-learn-more {
	display: inline-block;
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
	display: inline-flex;
	gap: var(--gap-x-small);
}
@media (max-width: 384px) {
	.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons {
		display: contents;
	}
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
}
@media (max-width: 384px) {
	.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-buttons button.cm-btn {
	}
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	/* justify-content: right; */
	align-items: baseline;
	gap: var(--gap-x-small);
}
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok a,
.klaro .cookie-notice:not(.cookie-modal-notice) .cn-body .cn-ok div {
}
.klaro .cookie-modal-notice {
	background-color: #333;
	background-color: var(--dark1, #333);
	color: #fafafa;
	color: var(--light1, #fafafa);
	z-index: 1001;
	/* box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 5px 5px 10px 0 rgba(0, 0, 0, 0.19); */
	width: 100%;
	max-height: 98%;
	top: 50%;
	-ms-transform: translateY(-50%);
			transform: translateY(-50%);
	position: fixed;
	overflow: auto;
	padding: var(--margin-inline-body);
	padding-top: 0.2em;
}
@media (min-width: 400px) {
	.klaro .cookie-modal-notice {
		border-radius: 4px;
		border-radius: var(--border-radius, 4px);
		position: relative;
		margin: 0 auto;
		max-width: 400px;
		height: auto;
		width: auto;
	}
}
.klaro .cookie-modal-notice .cn-ok {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 1em;
}
.klaro .cookie-notice-hidden {
	display: none !important;
}
