.o-teaser-projects {
	display: grid;
	grid-template-columns: auto auto;
	justify-content: space-between;
	align-items: baseline;
	row-gap: var(--space-small);
}

.o-teaser-projects__link {
	justify-self: end;
}

.o-teaser-projects__container {
	overflow: auto;
	grid-column: 1 / -1;
}

.o-teaser-projects__list {
	list-style: none;
	display: flex;
	gap: var(--gap-grid);

	> li {
		flex-basis: 50%;
		min-inline-size: calc(228rem / 16);
	}
}
