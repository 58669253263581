.a-lang-switch {
	--_backdrop-inset-block-start: var(--block-size-header);
	--_inset-block-start: var(--block-size-header);
	--_inset-inline-end: var(--margin-inline-body);
	--_color-content: var(--color-content-primary);
	--_color-content-current: var(--color-content-tertiary);
	--_color-background: var(--color-background-primary);
	--_color-background-hover: var(--color-background-secondary);

	position: absolute;
	inset-block-start: var(--_inset-block-start);
	inset-inline-start: auto;
	inset-inline-end: var(--_inset-inline-end);
	display: none;
	margin-block-start: calc(var(--gap-xx-small) * -1); /* gap-xx-small is padding-block-end of .o-header */
	margin-inline: calc(var(--gap-small) * -1);
	background-color: var(--_color-background);

	> ul {
		list-style: none;
	}

	&[data-show-popover] {
		display: block;
	}

	body[data-inverse] & {
		--_color-content: var(--color-content-inverse-primary);
		--_color-content-current: var(--color-content-inverse-tertiary);
		--_color-background: var(--color-background-inverse-primary);
		--_color-background-hover: var(--color-background-inverse-secondary);
	}
}

.a-lang-switch a {
	display: block;
	font: var(--font-label-medium);
	text-decoration: none;
	padding-inline: calc(var(--gap-small) * 2);
	padding-block: var(--gap-x-small);
	color: var(--_color-content);

	&[aria-current="page"] {
		color: var(--_color-content-current);
	}

	@media (pointer: fine) {
		&:hover {
			background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 8%);
		}
		&:active {
			background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 12%);
		}
	}
}
