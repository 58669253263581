.m-card-system {
	--_image-size: calc(104rem / 16);
	--_font-title: var(--font-heading-small);
	--_font-tag: var(--font-label-small);
	--_gap: var(--gap-small);
	--_padding-block-end: var(--gap-small);
	--_align: end;
	--_color-content: var(--color-content-primary);
	--_color-content-disabled: var(--color-content-tertiary);
	--_color-background: transparent;

	display: block;
	text-decoration: none;
	padding-inline-end: var(--_padding-block-end);
	color: var(--_color-content);
	background-color: var(--_color-background);
	transition-property: background-color, color;
	transition-duration: var(--duration-medium);

	&[data-size="xxx-small"] {
		--_font-title: var(--font-label-small);
		--_image-size: calc(16rem / 16);
		--_gap: var(--gap-x-small);
		--_padding-block-end: 0;
		--_align: center;
		padding: var(--gap-xxx-small);
		font-weight: 400;
	}
	&[data-size="xx-small"] {
		--_font-title: var(--font-label-small);
		--_image-size: calc(20rem / 16);
		--_gap: var(--gap-x-small);
		--_padding-block-end: 0;
		--_align: center;
		padding: var(--gap-xx-small);
	}
	&[data-size="x-small"] {
		--_font-title: var(--font-label-medium);
		--_image-size: calc(36rem / 16);
		--_padding-block-end: 0;
		--_align: center;
	}
	&[data-size="small"] {
		--_image-size: calc(80rem / 16);
		container-type: inline-size;
	}

	&:is(:disabled, [aria-disabled="true"]) {
		cursor: not-allowed;
		color: var(--_color-content-disabled);
	}

	&[data-variant="label"] {
		--_font-title: var(--font-label-small);
		padding-block: calc(6rem / 16);
		padding-inline: var(--gap-small);
		transition-property: color, background-color;
	}

	&[data-selected] {
		--_color-content: var(--color-content-inverse-primary);
		--_color-content-disabled: var(--color-content-inverse-tertiary);
		--_color-background: var(--color-background-inverse-primary);
		transition-property: color, background-color;
	}
	&[data-open] {
		--_color-content: var(--color-content-secondary);
	}

	&:not([data-size]) {
		container-type: inline-size;
	}

	body[data-inverse] & {
		--_color-content: var(--color-content-inverse-primary);
		--_color-content-disabled: var(--color-content-inverse-secondary);
	}

	@media (pointer: fine) {
		&:not(:where(:disabled, [aria-disabled="true"], [data-loader])) {
			&:hover, a:hover &[data-fake-link] {
				background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 8%);
			}
			&:active {
				background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 12%);
				transition-duration: var(--duration-x-small);
			}
			&[data-open] {
				&:hover, a:hover &[data-fake-link] {
					color: var(--color-content-primary);
				}
			}
			&[data-selected] {
				&:hover, a:hover &[data-fake-link] {
					background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 15%);
				}
				&:active {
					background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 20%);
				}
			}
			&[data-variant="label"] {
				&:hover, a:hover &[data-fake-link] {
					background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 4%);
				}
				&:active {
					background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 8%);
				}
			}
		}
	}

	@media (max-width: 32em) {
		&[data-variant="label"] {
			padding-inline: var(--gap-x-small);
		}
	}
}

.m-card-system__container {
	display: grid;
	grid-template-columns: var(--_image-size) auto;
	align-items: var(--_align);
	gap: var(--_gap);

	> img {
		transition-property: opacity;
		transition-duration: var(--duration-medium);
	}

	.m-card-system:is(:disabled, [aria-disabled="true"]) & {
		> img {
			opacity: var(--opacity-tertiary);
		}
	}
	.m-card-system[data-open]:not(:hover) & {
		> img {
			opacity: var(--opacity-secondary);
		}
	}

	@container (max-width: 360px) {
		.m-card-system:not([data-size]) &,
		.m-card-system:where([data-size="small"]) & {
			--_image-size: calc(80rem / 16);
			--_padding-block-end: var(--gap-x-small);
		}
	}
	@container (max-width: 320px) {
		.m-card-system:not([data-size]) &,
		.m-card-system:where([data-size="small"]) & {
			--_image-size: calc(68rem / 16);
			--_padding-block-end: var(--gap-x-small);
		}
	}
}

.m-card-system__text {
	padding-block-end: var(--_padding-block-end);
}

.m-card-system__title {
	display: block;
	font: var(--_font-title);
}

.m-card-system__tag {
	margin-block-start: var(--gap-xx-small);
	display: block;
	font: var(--_font-tag);
	color: var(--color-content-secondary);
}
