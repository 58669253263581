/* @font-face {
	font-family: 'vFutura';
	src: url('/assets/fonts/vfutura-light.woff2') format('woff2'), url('/assets/fonts/vfutura-light.woff') format('woff');
	font-weight: 300;
	font-display: swap;
	font-style: normal;
} */

@font-face {
	font-family: 'vFutura';
	src: url('/assets/fonts/vfutura-regular.woff2') format('woff2'), url('/assets/fonts/vfutura-regular.woff') format('woff');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'vFutura';
	src: url('/assets/fonts/vfutura-medium.woff2') format('woff2'), url('/assets/fonts/vfutura-medium.woff') format('woff');
	font-weight: 500;
	font-display: swap;
	font-style: normal;
}


@font-face {
	font-family: 'vFutura';
	src: url('/assets/fonts/vfutura-heavy.woff2') format('woff2'), url('/assets/fonts/vfutura-heavy.woff') format('woff');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}
