.a-hr {
	inline-size: 100%;
	margin-block: var(--gap-grid);
	border-block-start: calc(2rem / 16) solid var(--color-content-tertiary);

	&[data-kind="secondary"] {
		border-block-start-color: var(--color-border-opaque);
	}

	&[data-margin="small"] {
		margin-block: var(--gap-small);
	}
}
