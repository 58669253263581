.m-search-results {
	--_color-content: var(--color-content-primary);
	--_color-background: var(--color-background-primary);

	margin-block-start: calc(var(--gap-medium) * -1);
	padding-block-start: var(--gap-medium);
	color: var(--_color-content);
	background-color: var(--_color-background);

	body[data-inverse] & {
		--_color-content: var(--color-content-inverse-primary);
		--_color-background: var(--color-background-inverse-primary);
	}
}

.m-search-results__list {
	/* contains .a-search-result items */
	list-style: none;
}

.m-search-results__message {
	padding: var(--gap-small);
}

.m-search-results__link-all-results {
	display: block;
	padding-inline: var(--gap-medium);
	font: var(--font-label-medium);
	text-decoration: none;
	transition-property: background-color;
	transition-duration: var(--duration-x-small);

	.m-search-results[data-variant="combobox"] & {
		padding-block: var(--gap-medium); /* --gap-medium is padding of .a-search-result */
	}

	&[aria-selected="true"] {
		background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 8%);
	}

	@media (pointer: fine) {
		&:hover {
			background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 8%);
		}
		&:active {
			background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 12%);
		}
	}
}

.m-search-results__pagination {
	margin-block-start: var(--gap-large);
}
