.o-stories {
}

.o-stories__list {
	margin-inline: auto;
	max-inline-size: calc(var(--inline-size-content) + var(--gap-small) * 2);
	padding-inline: var(--gap-small);
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(calc(240rem / 16), 1fr));
	gap: var(--gap-grid);
	list-style: none;

	> li:first-of-type {
		grid-column: 1 / -1;
	}

	@media (min-width: 50em) {
		grid-template-columns: repeat(3, 1fr);
	}
}
