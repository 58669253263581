/**
 * Show text and image next to each other, when container is wider than 1208px (102.8em) (span over 12 layout columns).
 */
.m-teaser {
	inline-size: 100%;
	container-type: inline-size;
	color: var(--color-content);
	background-color: var(--color-background);
	background-image: var(--background-image);
	background-size: cover;
}

.m-teaser__container {
	--_font-heading: var(--font-heading-large);

	display: grid;

	.m-teaser[data-align="end"] & {
		align-items: end;
	}
	.m-teaser[data-align="center"] & {
		align-items: center;
	}
	.m-teaser[data-size="small"] & {
		--_font-heading: var(--font-heading-medium);
	}
	.m-teaser[data-size="large"] & {
		--_font-heading: var(--font-display-x-large);
	}

	@container (min-width: 61em) {
		.m-teaser[data-size="large"] & {
			--_font-heading: var(--font-display-xx-large);
		}
	}

	@container (min-width: 929px) {
		column-gap: var(--gap-grid);

		grid-template-columns:
			[breakout-start figure-start content-start] repeat(7, minmax(10px, var(--column)))
			[figure-end text-start] repeat(5, minmax(10px, var(--column)))
			[content-end text-end breakout-end];

		.m-teaser[data-reverse] & {
			grid-template-columns:
				[breakout-start text-start content-start] repeat(5, minmax(10px, var(--column)))
				[text-end figure-start] repeat(7, minmax(10px, var(--column)))
				[content-end figure-end breakout-end];
		}
		.m-teaser[data-size="large"] & {
			grid-template-columns:
				[breakout-start figure-start] minmax(0, 1fr)
				[content-start] repeat(8, minmax(10px, var(--column)))
				[figure-end text-start] repeat(4, minmax(10px, var(--column)))
				[content-end text-end breakout-end];

			.m-teaser[data-reverse] & {
				grid-template-columns:
					[breakout-start text-start content-start] repeat(4, minmax(10px, var(--column)))
					[text-end figure-start] repeat(8, minmax(10px, var(--column)))
					[content-end] minmax(0, 1fr) [figure-end breakout-end];
			}
		}
	}

	@container (min-width: 1208px) {
		grid-template-columns:
			[breakout-start] minmax(0, 1fr)
			[figure-start content-start] repeat(8, minmax(10px, var(--column)))
			[figure-end text-start] repeat(4, minmax(10px, var(--column)))
			[content-end text-end] minmax(0, 1fr) [breakout-end];

		.m-teaser[data-reverse] & {
			grid-template-columns:
				[breakout-start] minmax(0, 1fr)
				[text-start content-start] repeat(4, minmax(10px, var(--column)))
				[text-end figure-start] repeat(8, minmax(10px, var(--column)))
				[content-end figure-end] minmax(0, 1fr) [breakout-end];
		}
		.m-teaser[data-size="large"] & {
			grid-template-columns:
				[breakout-start figure-start] minmax(0, 1fr)
				[content-start] repeat(8, minmax(10px, var(--column)))
				[figure-end text-start] repeat(4, minmax(10px, var(--column)))
				[content-end text-end] minmax(0, 1fr) [breakout-end];

			.m-teaser[data-reverse] & {
				grid-template-columns:
					[breakout-start] minmax(0, 1fr)
					[text-start content-start] repeat(4, minmax(10px, var(--column)))
					[text-end figure-start] repeat(8, minmax(10px, var(--column)))
					[content-end] minmax(0, 1fr) [figure-end breakout-end];
			}
		}
	}
}

.m-teaser__texts {
	--_padding-inline: var(--margin-inline-body);
	--_padding-block-end: var(--gap-xxx-large);
	--_gap-row: var(--gap-x-large);
	max-inline-size: calc(792rem / 16);
	inline-size: 100%;
	padding-block-end: var(--_padding-block-end);
	padding-inline: var(--_padding-inline);

	.m-teaser[data-padding] & {
		padding-block: var(--gap-grid);
	}

	&:only-child {
		padding-block-start: var(--_padding-block-end);
	}

	@container not (min-width: 61.0rem) {
		padding-inline: var(--_padding-inline);
	}
	@container (min-width: 610px) {
		/* --_padding-inline: var(--gap-grid); */
	}

	@container not (min-width: 92.9rem) {
		.m-teaser__figure + & {
			margin-block-start: var(--_gap-row);
		}
	}
	@container (min-width: 929px) {
		grid-row-start: 1;
		grid-column: text;
		padding-block: var(--gap-grid);

		&:only-child {
			grid-column: content;
			max-inline-size: none;
			display: grid;
			grid-template-columns: subgrid;
			padding-inline-start: var(--_padding-inline);
		}

		.m-teaser:not([data-reverse]) &:not(:only-child) {
			padding-inline-start: 0;
		}
		.m-teaser[data-reverse] &:not(:only-child) {
			padding-inline-end: 0;
		}
		.m-teaser[data-size="large"] & {
			padding-block: var(--space-large);
		}
	}

	@container (min-width: 1208px) {
		--_padding-inline: 0;
	}
}

.m-teaser__heading {
	font: var(--_font-heading);

	.m-teaser__texts:only-child & {
		grid-column: figure;
	}
	.m-teaser__texts:has(.m-teaser__text):only-child & {
		grid-column: text;
	}
}

.m-teaser__text {
	font: var(--font-paragraph-large);

	.m-teaser__texts:only-child & {
		grid-column: figure;
	}
}
.m-teaser__heading + .m-teaser__text {
	margin-block-start: var(--gap-large);

	.m-teaser__texts:only-child & {
		/* vertical ryhtm */
		margin-block-start: calc(7rem / 16);
	}
}

.m-teaser__button {
	.m-teaser__texts:only-child & {
		grid-column: figure;
	}
}
.m-teaser__text + .m-teaser__button {
	margin-block-start: var(--gap-medium);
}
.m-teaser__heading + .m-teaser__button {
	margin-block-start: var(--gap-large);
}

.m-teaser__figure {
	.m-teaser:not([data-size="large"]) & {
		max-inline-size: calc(792rem / 16);
	}

	@container (min-width: 929px) {
		grid-row-start: 1;
		grid-column: figure;
	}
}
