.a-product-type-booknav-link {
	--_gap: var(--gap-xx-small);
	--_duration: var(--duration-medium);

	display: inline-flex;
	justify-content: center;
	align-items: start;
	column-gap: var(--_gap);
	text-decoration: none;
	font: var(--font-label-small);
	color: var(--color-content-secondary);
	transition-property: color;
	transition-duration: var(--_duration);

	strong {
		display: block;
		font-weight: 700;
		text-wrap: balance;
	}

	em {
		display: block;
		font-style: normal;
		text-wrap: balance;
	}

	@media (pointer: fine) {
		&:not(:where(:disabled, [aria-disabled="true"], [data-loader])) {
			&:hover, a:hover &[data-fake-link] {
				color: var(--color-content-primary);
			}
		}
	}
}

.a-product-type-booknav-link__icon {
	--_size: 1.2em;
	--_size: 1lh;

	flex-shrink: 0;

	> svg {
		inline-size: var(--_size);
		block-size: var(--_size);
	}

	> svg * {
		fill: currentcolor;
	}

	&:where([data-variant="arrow-right"], [data-variant="arrow-left"]) {
		polygon {
			transition-property: translate scale;
			transition-duration: var(--duration-small);
		}
	}
	&:where([data-variant="arrow-left"]) {
		polygon:last-child {
			transform-origin: right center;
		}
	}

	@media (pointer: fine) {
		.a-product-type-booknav-link:not(:where(:disabled, [aria-disabled="true"], [data-loader])) & {
			.a-product-type-booknav-link:hover &[data-variant="arrow-right"],
			a:hover .a-product-type-booknav-link[data-fake-link] &[data-variant="arrow-right"] {
				polygon {
					transition-delay: var(--_delay);
				}
				polygon:first-child {
					translate: 2px 0;
				}
				polygon:last-child {
					scale: 105% 1;
				}
			}
			.a-product-type-booknav-link:hover &[data-variant="arrow-left"],
			a:hover .a-product-type-booknav-link[data-fake-link] &[data-variant="arrow-left"] {
				polygon {
					transition-delay: var(--_delay);
				}
				polygon:first-child {
					translate: -2px 0;
				}
				polygon:last-child {
					scale: 105% 1;
				}
			}
		}
	}
}
