.m-search {
	--_max-inline-size: calc(688rem / 10 + var(--margin-inline-body) * 2);
	--_padding-block-start: 0;
	--_padding-block-end: var(--gap-x-small);
	--_color-background: var(--color-background-primary);

	position: absolute;
	inset-block-start: var(--block-size-header);
	inset-inline-start: 0;
	inset-inline-end: 0;
	display: none;
	margin: 0;
	max-inline-size: none;
	max-block-size: none;
	inline-size: 100%;
	overflow: unset;
	background-color: transparent;

	> * {
		grid-column: 1 / -1;
		grid-row: 1 / -1;
	}

	&::backdrop {
		background-color: transparent;
	}

	&[open] {
		display: grid;
		align-items: start;
	}

	&[data-show-popover] {
		display: block;
	}

	body[data-inverse] & {
		--_color-background: var(--color-background-inverse-primary);
	}

	@media (min-width: 61em) {
		--_padding-block-start: var(--gap-small);
		--_padding-block-end: var(--gap-medium);
	}
}

.m-search__container {
	z-index: 1;
	inline-size: 100%;
	max-block-size: calc(36rem / 10 + var(--_padding-block-start) + var(--_padding-block-end));
	background-color: var(--_color-background);
}

.m-search__form {
	margin-inline: auto;
	grid-column: input;
	max-inline-size: var(--_max-inline-size);

	@media (min-width: 61em) {
		padding-inline: var(--margin-inline-body);
	}
}

.m-search__input {
	padding-block-start: var(--_padding-block-start);
	padding-block-end: var(--_padding-block-end);
	padding-inline: var(--margin-inline-body);

	body[data-inverse] & {
		color: var(--color-content-inverse-primary);
	}

	@media (min-width: 61em) {
		padding-inline: 0;
	}
}
