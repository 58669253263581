.o-footer {
	--_margin-block-start: var(--space-xx-large);
	margin-block-start: auto;

	@media (min-width: 40em) {
		--_margin-block-start: var(--space-xxx-large);
	}
}
:where(.o-project, .o-system, .o-story) + .o-footer {
	--_margin-block-start: var(--space-x-large);
}

.o-footer__content {
	margin-block-start: var(--_margin-block-start);
	margin-inline: auto;
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	padding-inline: var(--margin-inline-body);

	@media print {
		> *:not(.a-text[data-size="small"]) {
			display: none;
		}
	}
}

.o-footer__box {
	margin-block-start: var(--space-large);
	color: var(--color-content-inverse-primary);
	background-color: var(--color-background-inverse-primary);

	@media print {
		display: none;
	}
}
.o-footer__content + .o-footer__box {
	margin-block-start: var(--space-medium);
	@media (min-width: 40em) {
		margin-block-start: var(--space-large);
	}
}

.o-footer__container {
	margin-inline: auto;
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: start;
	padding-inline: var(--margin-inline-body);
	padding-block-start: var(--space-medium);
	padding-block-end: var(--space-small);
	flex-direction: column;
	row-gap: var(--space-small);

	@media (min-width: 40em) {
		padding-block-start: var(--space-large);
	}
	@media (min-width: 61em) {
		grid-template-columns: repeat(12, 1fr);
		column-gap: var(--gap-grid);
		row-gap: calc(48rem / 16);
		align-items: start;
		justify-items: start;
	}
}


.o-footer__breadcrumbs {
	grid-column: 1 / -1;
	align-self: stretch;
	margin: calc(var(--margin-inline-body) * -1);
	overflow: auto;
	display: flex;
	scrollbar-width: none;

	> ul {
		flex-shrink: 0;
		display: flex;
		padding: var(--margin-inline-body);
		list-style: none;
		gap: var(--gap-xx-small);

		> li {
			display: flex;
			gap: inherit;
			flex-wrap: nowrap;
			flex-shrink: 0;
		}
	}

	&::-webkit-scrollbar {
		display: none;
	}
}

.o-footer__logo-link {
	padding-block: var(--gap-x-small);
	padding-inline: var(--margin-inline-body);
	margin-inline-start: calc(var(--margin-inline-body) * -1);

	> img {
		display: block;
		block-size: calc(40rem / 16);
		inline-size: auto;
	}

	@media not (min-width: 61em) {
		grid-row-start: 2;
	}
	@media (min-width: 61em) {
		grid-column: 1 / span 3;
	}
}

.o-footer__sitemap {
	grid-column: 1 / -1;
	display: flex;
	flex-direction: column;
	row-gap: var(--gap-x-large);

	@media not (min-width: 61em) {
		grid-row-start: 3;
	}
	@media (min-width: 61em) {
		max-inline-size: calc(var(--column) * 6 + var(--gap-grid) * 5);
		grid-column: 5 / 11;
		margin-block-start: calc(22rem / 16);
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		column-gap: var(--gap-grid);
	}
}

.o-footer__sitemap-column {
	grid-column-end: span 2;
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: var(--gap-small);
}

.o-footer__to-top {
	grid-column: 2 / -1;
	justify-self: end;

	@media not (min-width: 61em) {
		align-self: center;
		grid-row-start: 2;
	}
	@media (min-width: 61em) {
		grid-column: 12 / -1;
		margin-block-start: calc(12rem / 16);
	}
}

.o-footer__copyright {
	grid-column: 1 / -1;

	@media not (min-width: 61em) {
		grid-row-start: 4;
	}
	@media (min-width: 61em) {
		grid-column: 1 / span 3;
		align-self: center;
	}
}

.o-footer__legal-nav {
	grid-column: 1 / -1;
	display: flex;
	flex-wrap: wrap;
	column-gap: var(--gap-large);
	row-gap: var(--gap-small);

	@media not (min-width: 61em) {
		grid-row-start: 5;
	}
	@media (min-width: 61em) {
		grid-column: 5 / span 4;
		align-self: center;
		column-gap: var(--gap-x-large);
	}
}

.o-footer__social-media {
	grid-column: 1 / -1;
	display: flex;
	gap: var(--gap-x-small);

	svg {
		inline-size: var(--size-icon-medium);
		block-size: var(--size-icon-medium);
	}

	svg * {
		fill: currentcolor;
	}

	@media not (min-width: 61em) {
		grid-row-start: 6;
	}
	@media (min-width: 61em) {
		grid-column: 9 / span 2;
		margin-inline-start: calc(var(--gap-x-small) * -1);
	}
}
