.o-layouts {
	display: grid;
	grid-template-columns:
		[breakout-start] minmax(var(--margin-inline-body), 1fr)
		[content-start] min(var(--inline-size-content), calc(100% - var(--margin-inline-body) * 2)) [content-end]
		minmax(var(--margin-inline-body), 1fr) [breakout-end];
}

.o-layouts__row {
	--_color-content: var(--color-content, inherit);
	--_color-background: var(--color-background, inherit);
	--_margin-block-start: var(--margin-block-start, var(--space-x-large));

	grid-column: content;
	color: var(--_color-content);
	background-color: var(--_color-background);
	background-image: var(--background-image);
	background-size: cover;

	&[data-breakout="breakout"],
	&[data-breakout="breakout-center"] {
		grid-column: breakout;
	}
	&[data-padding-inline] {
		padding-inline: var(--margin-inline-body);
	}
	&[data-padding-block] {
		padding-block: var(--margin-inline-body);
	}
	&[data-margin-block-start="small"] {
		--_margin-block-start: var(--gap-small);
	}
	&[data-margin-block-start="medium"] {
		--_margin-block-start: var(--space-medium);
	}
	/* &[data-margin-block-start="large"] {
		--_margin-block-start: var(--space-x-large);
	} */
	&[data-margin-block-start="none"] {
		--_margin-block-start: 0;
	}

	& + & {
		margin-block-start: var(--_margin-block-start);
	}

	@media (min-width: 61em) {
		&[data-padding-inline] {
			padding-inline: var(--gap-grid);
		}
		&[data-padding-block] {
			padding-block: var(--gap-grid);
		}
	}
}
.o-layouts__row:where([data-breakout="breakout-center"], [data-breakout="breakout"]) {
	@media (max-width: 77em) {
		:where(.a-text, .a-heading) {
			padding-inline: var(--margin-inline-body);
		}
	}
}

.o-layouts__row-content {
	.o-layouts__row[data-breakout="breakout-center"] & {
		max-inline-size: var(--inline-size-content);
		margin-inline: auto;
	}
}
