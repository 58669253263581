.o-roomle-configurator {
	padding-block-end: calc(var(--space-xxx-large) - var(--space-x-large));

	iframe {
		aspect-ratio: 16 / 10;
		max-block-size: calc(100vh - var(--space-large));
	}
}

.o-roomle-configurator__buttons {
	margin-block-start: var(--space-large);
	margin-inline: auto;
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	padding-inline: var(--margin-inline-body);
}
