.m-product-type {
	--_image-size: calc(56rem / 16);
	--_image-size-extended: var(--_image-size);
	--_size-column-text: minmax(10px, auto);
	--_size-column-button: calc(44rem / 16);
	--_row-gap: var(--gap-small);
	--_column-gap: var(--gap-small);
	--_background-color-active: var(--color-background-secondary);
	--_margin-inline-start: calc(var(--_image-size-extended) - var(--_image-size));
	--_duration: var(--duration-medium);

	position: relative;
	cursor: pointer;
	scroll-margin-block-start: var(--gap-x-small);
	display: grid;
	grid-template-columns: var(--_image-size) var(--_size-column-text);
	grid-template-rows:
		[title-start] minmax(var(--_image-size), auto)
		[title-end systems-start] minmax(0, auto)
		[systems-end details-start] minmax(0, auto)
		[details-end products-start] auto
		[products-end delivery-start] auto
		[delivery-end details-products-start] auto
		[details-products-end footer-start] auto
		[footer-end button-close-start] auto
		[button-close-end border-start] auto [border-end]
	;
	column-gap: var(--_column-gap);
	align-items: start;
	margin-inline-start: var(--_margin-inline-start);
	font: var(--font-paragraph-medium);
	transition-property: grid-template-columns, margin-inline-start;
	transition-duration: var(--_duration);
	transition-timing-function: ease-out;

	&[data-extended] {
		grid-template-columns: var(--_image-size-extended) var(--_size-column-text);
		cursor: initial;
		margin-inline-start: 0;
		transition-duration: var(--_duration);
		transition-delay: calc(var(--_duration) * 0.75);
	}
	&[data-variant="standalone"] {
		margin-inline-start: 0;
		cursor: initial;
	}
	&[data-variant="overview"] {
		max-inline-size: calc(584rem / 16);
		margin-inline-start: 0;
	}

	a& {
		text-decoration: none;
	}

	@media (min-width: 38em) {
		--_column-gap: var(--gap-x-large);
		--_image-size-extended: calc(120rem / 16);

		margin-inline-end: calc(var(--_image-size-extended) - var(--_image-size));
		grid-template-columns: var(--_image-size) var(--_size-column-text) var(--_size-column-button);
		grid-template-rows:
			[title-start] minmax(var(--_image-size), auto)
			[title-end systems-start] auto
			[systems-end details-start] auto
			[details-end products-start] auto
			[products-end delivery-start] auto
			[delivery-end details-products-start] auto
			[details-products-end footer-start] auto
			[footer-end] 1fr
			[border-start] auto [border-end]
		;

		&[data-extended] {
			grid-template-columns: var(--_image-size-extended) var(--_size-column-text) var(--_size-column-button);
		}
		&[data-variant="standalone"] {
			grid-template-columns: var(--_image-size-extended) var(--_size-column-text) var(--_size-column-button);
		}
		&[data-variant="overview"] {
			margin-inline-end: 0;
			grid-template-columns: var(--_image-size) var(--_size-column-text) 0;
		}
	}

	@media (min-width: 50em) {
		--_image-size-extended: calc(240rem / 16);
	}

	@media (min-width: 72em) {
		--_size-column-text: minmax(auto, calc(516rem / 16));
		--_image-size-extended: calc(272rem / 16);

		justify-content: end;
		margin-inline-end: 0;
	}
}

.m-product-type__link {
	display: block;
	height: 100%;
	grid-column: 1 / -1;
	grid-row: title;
	pointer-events: none;

	.m-product-type[data-extended] & {
		display: none;
	}

	@media (scripting: none) {
		position: relative;
		pointer-events: auto;
	}
}

.m-product-type__title {
	display: grid;
	min-block-size: var(--_image-size);
	align-items: center;
	align-content: center;
	grid-column-start: 2;
	grid-row: title;
	margin-block: var(--gap-small);
	font: var(--font-heading-x-small);
	transition-property: margin-block-start;
	transition-duration: var(--_duration);

	> strong {
		display: block;
		text-wrap: balance;
	}
	> span {
		font-weight: 400;
	}

	h1& {
		font: var(--font-display-small);
	}

	.m-product-type[data-variant="standalone"] & {
		grid-column-end: -1;
		margin-block: 0;
	}

	@media (min-width: 38em) {
		font-size: var(--font-size-label-large);

		.m-product-type[data-extended] & {
			margin-block-start: var(--gap-large);
		}
	}
}

.m-product-type__button-close {
	justify-self: end;

	@media not (min-width: 38em) {
		margin-block-end: var(--gap-medium);
		grid-row: button-close;
		grid-column: 1 / -1;
		justify-self: center;
	}
	@media (min-width: 38em) {
		margin-block-start: calc(14rem / 16);
		grid-column-start: 3;
		grid-row-start: title-start;
	}
}

.m-product-type__images {
	--_row-gap: var(--gap-large);

	display: grid;
	row-gap: 0;
	grid-column-start: 1;
	grid-row-start: title-start;
	grid-row-end: details-end;
	margin-block: var(--gap-small);
	transition-property: row-gap, margin-block-end;
	transition-duration: var(--_duration);

	img {
		background-color: #F2F2F2;
	}

	.m-product-type[data-extended] & {
		row-gap: var(--gap-large);
	}

	.m-product-type[data-variant="standalone"] & {
		margin-block-start: 0;
		row-gap: var(--gap-large);
	}

	@media (min-width: 38em) {
		grid-row-end: border-start;

		.m-product-type[data-extended] & {
			margin-block-end: var(--space-medium);
		}
	}

	@media (min-width: 72em) {
		--_row-gap: var(--gap-grid);
	}
}

.m-product-type__product-image {
	width: 100%;
	aspect-ratio: 1;
}

.m-product-type__tech-image {
	display: block;
	overflow: hidden;
}

.m-product-type__details {
	grid-column-start: 2;
	grid-column-end: 3;
	overflow: hidden;

	> * + * {
		margin-block-start: var(--gap-x-small);
	}

	&:not(.-products) {
		grid-row: details;
	}

	&.-products {
		grid-row: details-products;
		grid-column-start: 1;
		padding-block-start: var(--gap-x-small);
	}

	.m-product-type[data-variant="standalone"] & {
		grid-column-end: -1;
	}

	@media (min-width: 38em) {
		&.-products {
			grid-column-start: 2;
		}
	}
}
.m-product-type__systems + .m-product-type__details {
	margin-block-start: var(--gap-x-large);
}
.m-product-type__images + .m-product-type__details {
	margin-block-start: var(--gap-x-large);
}

.m-product-type__detail {
	/* Similar to a-text */
	--_gap-paragraph: var(--gap-xx-small);

	p, ol, ul {
		* + & {
			margin-block-start: var(--_gap-paragraph);
		}
	}

	ol, ul {
		padding-inline-start: var(--gap-medium);
		li + li {
			margin-block-start: var(--_gap-paragraph);
		}
		li {
			* + :is(ul, ol) {
				margin-block-start: calc(var(--_gap-paragraph) * 0.5);
			}
		}
	}
	ul {
		li {
			position: relative;
			list-style: none;
			&::before {
				content: '';
				position: absolute;
				inset-inline-start: calc(var(--gap-medium) * -1);
				inset-block-start: calc(1.4em * 0.5);
				inset-block-start: calc(1lh * 0.5);
				transform: translateY(-50%);
				width: calc(4rem / 16);
				height: calc(4rem / 16);
				background-color: currentcolor;
				border-radius: 50%;
			}

			ul {
				margin-inline-start: var(--gap-medium);
			}

		}
	}

	&:where(.-warning, .-assembly-instruction, .-service-drawing) {
		display: grid;
		grid-template-columns: calc(var(--size-icon-medium) + var(--gap-small)) 1fr;

		&::before {
			content: '';
			display: block;
			margin-block-start: calc(1rem / 16);
			width: var(--size-icon-medium);
			height: var(--size-icon-medium);
		}
	}

	&.-warning,
	&.-instruction-text {
		padding: var(--gap-small);
		background-color: var(--color-background-secondary);
	}

	&.-warning {
		&::before {
			background-image: url('../images/symbol-warning.svg');
			background-size: 100%;
			background-repeat: no-repeat;
		}
	}

	&.-assembly-instruction {
		&::before {
			mask-image: url('../images/symbol-notice.svg');
			-webkit-mask-image: url('../images/symbol-notice.svg');
			mask-size: 100%;
			mask-repeat: no-repeat;
			background-color: currentcolor;
		}
	}

	&.-service-drawing {
		&::before {
			background-image: url('../images/symbol-service.svg');
			background-size: 100%;
			background-repeat: no-repeat;
		}
	}
}

.m-product-type__delivery {
	grid-row: delivery;
	grid-column-start: 1;
	grid-column-end: -1;
	padding-block-start: var(--gap-medium);
	display: flex;
	align-items: center;
	column-gap: var(--gap-small);

	> .a-icon {
		margin-block-start: calc(-2rem / 16); /* horizontal alignment */
		margin-inline-start: calc(-1rem / 16); /* vertical alignment */
	}

	@media (min-width: 23.75em) {
		grid-column-start: 2;
	}
}

.m-product-type__systems {
	margin-block-start: var(--gap-x-large);
	grid-row: systems;
	grid-column-start: 2;
	grid-column-end: -1;
	overflow: hidden;

	> h2 {
		font: var(--font-heading-xx-small);
	}

	> ul {
		margin-block-start: var(--gap-xx-small);
		display: flex;
		flex-wrap: wrap;
		column-gap: var(--gap-x-small);
		row-gap: var(--gap-xxx-small);

		> li {
			flex-grow: 0;
			display: flex;
		}
	}
}

.m-product-type__products {
	margin-block-start: var(--gap-grid);
	grid-row: products;
	grid-column-start: 1;
	grid-column-end: -1;
	overflow: hidden;

	@media (min-width: 38em) {
		grid-column-start: 2;
	}
}

.m-product-type__footer {
	margin-inline-start: calc(-9rem / 16); /* vertical alignment */
	margin-block-start: calc(var(--gap-medium) + 2rem / 16);
	margin-block-end: var(--gap-medium);
	grid-column-start: 1;
	grid-column-end: -1;
	grid-row: footer;
	overflow: hidden;

	@media (min-width: 38em) {
		grid-column-start: 2;
		margin-block-end: var(--space-medium);
	}
}

.m-product-type__backdrop {
	z-index: -1;
	grid-column: 1 / -1;
	grid-row: title;
	inline-size: 100%;
	block-size: 100%;
	background-color: transparent;
	transition-property: background-color;
	transition-duration: var(--duration-small);

	.m-product-type:not([data-extended]):where(:hover, :focus-within) & {
		background-color: var(--_background-color-active);
	}
}

.m-product-type__border {
	grid-column: 1 / -1;
	grid-row: border;
	order: 10;
	justify-self: end;
	inline-size: 100%;
	max-inline-size: calc(var(--_size-column-text) + var(--_size-column-button) + var(--gap-grid) * 2 + var(--_image-size));
	block-size: var(--size-border);
	background-color: currentcolor;
}
