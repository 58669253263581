:root {
	--font-family: vFutura, Arial, sans-serif;

	--font-size-display-xx-large: calc(67rem / 16);
	--font-size-display-x-large: calc(54rem / 16);
	--font-size-display-large: calc(43rem / 16);
	--font-size-display-medium: calc(37rem / 16);
	--font-size-display-small: calc(33rem / 16);
	--font-size-heading-x-large: calc(35rem / 16);
	--font-size-heading-large: calc(31rem / 16);
	--font-size-heading-medium: calc(26rem / 16);
	--font-size-heading-small: calc(23rem / 16);
	--font-size-heading-x-small: calc(20rem / 16);
	--font-size-heading-xx-small: calc(17rem / 16);
	--font-size-label-x-large: calc(23rem / 16);
	--font-size-label-large: calc(20rem / 16);
	--font-size-label-medium: calc(17rem / 16);
	--font-size-label-small: calc(15rem / 16);
	--font-size-label-x-small: calc(13rem / 16);
	--font-size-paragraph-x-large: calc(23rem / 16);
	--font-size-paragraph-large: calc(20rem / 16);
	--font-size-paragraph-medium: calc(17rem / 16);
	--font-size-paragraph-small: calc(15rem / 16);
	--font-size-paragraph-x-small: calc(13rem / 16);

	--line-height-display-xx-large: calc(68rem / 16);
	--line-height-display-x-large: calc(60rem / 16);
	--line-height-display-large: calc(48rem / 16);
	--line-height-display-medium: calc(44rem / 16);
	--line-height-display-small: calc(36rem / 16);
	--line-height-heading-x-large: calc(40rem / 16);
	--line-height-heading-large: calc(36rem / 16);
	--line-height-heading-medium: calc(32rem / 16);
	--line-height-heading-small: calc(28rem / 16);
	--line-height-heading-x-small: calc(24rem / 16);
	--line-height-heading-xx-small: calc(20rem / 16);
	--line-height-label-x-large: calc(28rem / 16);
	--line-height-label-large: calc(24rem / 16);
	--line-height-label-medium: calc(20rem / 16);
	--line-height-label-small: calc(16rem / 16);
	--line-height-label-x-small: calc(16rem / 16);
	--line-height-paragraph-x-large: calc(28rem / 16);
	--line-height-paragraph-large: calc(28rem / 16);
	--line-height-paragraph-medium: calc(24rem / 16);
	--line-height-paragraph-small: calc(20rem / 16);
	--line-height-paragraph-x-small: calc(20rem / 16);

	--font-display-xx-large: 600 var(--font-size-display-xx-large)/var(--line-height-display-xx-large) var(--font-family);
	--font-display-x-large: 600 var(--font-size-display-x-large)/var(--line-height-display-x-large) var(--font-family);
	--font-display-large: 600 var(--font-size-display-large)/var(--line-height-display-large) var(--font-family);
	--font-display-medium: 600 var(--font-size-display-medium)/var(--line-height-display-medium) var(--font-family);
	--font-display-small: 600 var(--font-size-display-small)/var(--line-height-display-small) var(--font-family);
	--font-heading-x-large: 600 var(--font-size-heading-x-large)/var(--line-height-heading-x-large) var(--font-family);
	--font-heading-large: 600 var(--font-size-heading-large)/var(--line-height-heading-large) var(--font-family);
	--font-heading-medium: 600 var(--font-size-heading-medium)/var(--line-height-heading-medium) var(--font-family);
	--font-heading-small: 600 var(--font-size-heading-small)/var(--line-height-heading-small) var(--font-family);
	--font-heading-x-small: 600 var(--font-size-heading-x-small)/var(--line-height-heading-x-small) var(--font-family);
	--font-heading-xx-small: 600 var(--font-size-heading-xx-small)/var(--line-height-heading-xx-small) var(--font-family);
	--font-label-x-large: 500 var(--font-size-label-x-large)/var(--line-height-label-x-large) var(--font-family);
	--font-label-large: 500 var(--font-size-label-large)/var(--line-height-label-large) var(--font-family);
	--font-label-medium: 500 var(--font-size-label-medium)/var(--line-height-label-medium) var(--font-family);
	--font-label-small: 500 var(--font-size-label-small)/var(--line-height-label-small) var(--font-family);
	--font-label-x-small: 500 var(--font-size-label-x-small)/var(--line-height-label-x-small) var(--font-family);
	--font-paragraph-x-large: 400 var(--font-size-paragraph-x-large)/var(--line-height-paragraph-x-large) var(--font-family);
	--font-paragraph-large: 400 var(--font-size-paragraph-large)/var(--line-height-paragraph-large) var(--font-family);
	--font-paragraph-medium: 400 var(--font-size-paragraph-medium)/var(--line-height-paragraph-medium) var(--font-family);
	--font-paragraph-small: 400 var(--font-size-paragraph-small)/var(--line-height-paragraph-small) var(--font-family);
	--font-paragraph-x-small: 400 var(--font-size-paragraph-x-small)/var(--line-height-paragraph-x-small) var(--font-family);

	--hyphenate-limit: 7;
}
