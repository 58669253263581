.o-catalog {
	margin-inline: auto;
	display: grid;
	justify-items: stretch;

	@media not (min-width: 72em) {
		max-inline-size: 100%;
		grid-template-columns: var(--margin-inline-body) [content-start] calc(100% - var(--margin-inline-body) * 2) [content-end] var(--margin-inline-body);
		row-gap: var(--gap-large);
	}

	@media (min-width: 72em) {
		max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
		grid-template-columns: repeat(12, 1fr);
		column-gap: var(--gap-grid);
		align-items: start;
		padding-inline: var(--margin-inline-body);
	}
}

.o-catalog__filter {
	z-index: 1;
	max-inline-size: 100%;
	background-color: var(--color-background-primary);

	@media (min-height: 38em) and (min-width: 72em) {
		position: sticky;
		inset-block-start: var(--space-medium);
	}

	@media not (min-width: 72em) {
		order: 1;
		position: sticky;
		inset-block-end: 0;
		margin-block-start: var(--gap-medium);
		grid-column: 1 / -1;
		overflow: auto;
		display: flex;
		padding-block: var(--gap-small);
		scrollbar-width: none;
		background-color: color-mix(in hsl, var(--color-background-primary), transparent 20%);
		backdrop-filter: blur(20px);

		&::-webkit-scrollbar {
			display: none;
		}
	}

	@media (min-width: 72em) {
		grid-column-end: span 3;
	}
}

.o-catalog__products {
	inline-size: 100%;

	@media not (min-width: 72em) {
		grid-column: content;
	}

	@media (min-width: 72em) {
		grid-column-end: span 9;
		justify-self: end;
	}
}

.o-catalog__info {
	margin-inline-start: auto;
	inline-size: 100%;
	max-inline-size: calc(664rem / 16);
}
:where(.o-catalog__info, .m-product-type) + .o-catalog__info {
	margin-block-start: var(--space-small);
	@media (min-width: 72em) {
		margin-block-start: var(--space-large);
	}
}
