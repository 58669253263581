/* Header inside of main element with headline, teaser and secondary navigation */

.m-main-header {
	--_margin-inline: var(--margin-inline-body);

	margin-inline: auto;
	margin-block-start: var(--space-x-small);
	margin-block-end: var(--space-medium);
	max-inline-size: calc(var(--inline-size-content) + var(--_margin-inline) * 2);
	display: grid;
	grid-template-columns: minmax(10px, 1fr);
	padding-inline: var(--_margin-inline);

	@media (min-width: 61em) {
		margin-block-start: var(--space-large);
		margin-block-end: var(--space-large);
		grid-template-columns: repeat(12, 1fr);
		column-gap: var(--gap-grid);
	}
}

.m-main-header__heading {
	order: -1;

	> h1 {
		font: var(--font-display-medium);

		> small {
			display: block;
			font: var(--font-heading-x-small);
			margin-block-start: calc(var(--line-height-heading-x-small) * -0.5);
		}
	}

	@media (min-width: 61em) {
		grid-column-end: span 5;

		> h1 {
			font: var(--font-display-large);

			> small {
				font: var(--font-heading-small);
				margin-block-start: calc(var(--line-height-heading-small) * -1);
			}
		}

		.m-main-header:not(:has(.m-main-header__navigation)) & {
			grid-column-end: span 12;
		}
	}
}

.m-main-header__navigation {
	@media not (min-width: 61em) {
		margin-inline: calc(var(--margin-inline-body) * -1);
		padding-inline: calc(var(--margin-inline-body) - var(--gap-x-small));
		padding-block: var(--gap-xx-small);
		display: flex;
		flex-wrap: nowrap;
		overflow: auto;
		scrollbar-width: none;

		> * {
			flex-shrink: 0;
		}

		&::-webkit-scrollbar {
			display: none;
		}

		&[data-variant="filter"] {
			margin-block-start: calc(4rem / 16);
			padding-inline: var(--margin-inline-body);
		}
	}
	@media (min-width: 61em) {
		grid-column-end: span 7;
		justify-self: end;
		align-self: end;
		margin-block-end: calc(-6rem / 16);

		&[data-variant="filter"] {
			margin-block-start: var(--space-x-small);
			grid-column: 1 / -1;
			justify-self: start;
		}
	}
}

.m-main-header__intro {
	margin-block-start: var(--gap-medium);
	text-wrap: balance;

	@media (min-width: 61em) {
		margin-block-start: var(--gap-x-large);
		grid-column-start: 1;
		grid-column-end: span 12;
	}
}

.m-main-header__filter {
	margin-block-start: var(--gap-large);

	@media (min-width: 61em) {
		grid-column: 1 / -1;
	}
}
