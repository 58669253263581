.m-banner {
	padding: var(--gap-x-large);
	background-color: var(--color-background-secondary);

	&[data-kind="leading-icon"] {
		display: grid;
		grid-template-columns: calc(40rem / 16) 1fr;
		column-gap: var(--gap-medium);
	}

	&[data-theme="inverse"] {
		color: var(--color-content-inverse-primary);
		background-color: var(--color-background-inverse-primary);
	}
	&[data-theme="positive"] {
		background-color: var(--color-background-light-positive);
	}
}
