.o-teaser-systems {
	@media (min-width: 61em) {
		max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
		margin-inline: auto;
		padding-inline: var(--margin-inline-body);
	}
}

.o-teaser-systems__heading {
	@media not (min-width: 61em) {
		padding-inline: var(--margin-inline-body);
	}
}

.o-teaser-systems__container {
	margin-block-start: var(--space-small);

	@media not (min-width: 61em) {
		display: flex;
		overflow: auto;
		scrollbar-width: none;
		scroll-snap-type: x mandatory;
		scroll-padding-inline-end: 80vw;
		scroll-margin-inline-end: 80vw;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.o-teaser-systems__list {
	display: grid;
	gap: var(--gap-medium);
	list-style: none;

	@media not (min-width: 61em) {
		grid-auto-columns: clamp(calc(264rem / 16), calc(100vw - 136rem / 16), calc(368rem / 16));
		grid-template-rows: repeat(4, auto);
		grid-auto-flow: column;
		padding-inline: var(--margin-inline-body);
		padding-inline-end: calc(100vw - calc(224rem / 16));

		> li {
			scroll-snap-align: start;
			scroll-margin-inline-start: var(--margin-inline-body);
		}
	}
	@media (min-width: 61em) {
		grid-template-columns: repeat(12, 1fr);
		column-gap: var(--gap-grid);

		> * {
			grid-column-end: span 4;
		}
	}
}
