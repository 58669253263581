.m-stack {
	--_gap: var(--gap-medium);

	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: start;
	gap: var(--_gap);
	list-style: none; /* remove list style when used for ol/ul element */

	&[data-size="null"] {
		--_gap: 0;
	}
	&[data-size="xx-small"] {
		--_gap: var(--gap-xx-small);
	}
	&[data-size="x-small"] {
		--_gap: var(--gap-x-small);
	}
	&[data-size="small"] {
		--_gap: var(--gap-small);
	}
	&[data-size="grid"],
	&[data-size="large"] {
		--_gap: var(--gap-grid);
	}

	&[data-direction="column"] {
		flex-direction: column;
	}

	&[data-align="center"] {
		align-items: center;
	}

	&[data-justify="end"] {
		justify-content: end;
	}

	&[data-justify="center"] {
		justify-content: center;
	}
}

:not(.m-stack) > :where(.a-display, .a-heading, .m-figure, .a-text, .m-stack) + .m-stack {
	margin-block-start: var(--gap-large);
}
