.a-button-download {
	--_color-content: var(--color-content-primary);
	--_color-background: var(--color-background-tertiary);

	display: grid;
	grid-template-columns: auto 1fr;
	column-gap: var(--gap-small);
	row-gap: var(--gap-xxx-small);
	padding-block: var(--gap-small);
	padding-inline: var(--gap-medium);
	font: var(--font-label-medium);
	text-decoration: none;
	color: var(--_color-content);
	background-color: var(--_color-background);
	transition-property: background-color;
	transition-duration: var(--duration-medium);

	> svg {
		block-size: var(--size-icon-medium);
		inline-size: var(--size-icon-medium);
		grid-row-end: span 2;
		color: var(--color-content-secondary);
		transition-property: color;
		transition-duration: inherit;

		* {
			fill: currentcolor;
		}
	}

	> strong {
		grid-column-start: 2;
		min-block-size: calc(var(--line-height-label-medium) * 2);
		display: block;
		font-weight: inherit;
		text-wrap: balance;
	}

	> small {
		grid-column-start: 2;
		display: flex;
		column-gap: var(--gap-x-small);
		font: var(--font-label-x-small);
		color: var(--color-content-secondary);
	}

	@media (pointer: fine) {
		&:hover {
			background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 4%);

			> svg {
				color: currentcolor;
			}
		}
		&:active {
			background-color: color-mix(in hsl, var(--_color-background), black 8%);
		}
	}
}
