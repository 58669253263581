.m-filter-catalog {
	align-self: start;

	@media not (min-width: 72em) {
		padding-inline: var(--margin-inline-body);
	}
}

.m-filter-catalog__container {
	@media not (min-width: 72em) {
		max-inline-size: 100%;
		display: flex;
		column-gap: var(--gap-medium);
		align-items: end;
	}
	@media (min-width: 72em) {
		> fieldset + :where(fieldset, .a-button) {
			margin-block-start: var(--gap-xx-large);
		}
	}
}

.m-filter-catalog__label {
	font: var(--font-label-medium);
	color: var(--color-content-secondary);

	@media not (min-width: 61em) {
		position: sticky;
		inset-inline-start: var(--margin-inline-body);
	}
}

.m-filter-catalog__list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	gap: var(--gap-x-small);

	@media not (min-width: 72em) {
		flex-wrap: nowrap;
	}
}

.m-filter-catalog__label + .m-filter-catalog__list {
	margin-block-start: var(--gap-small);
}
