/**
 * For input (text) or textarea element
 */
.a-input {
	--_padding-block: var(--gap-xx-small);
	--_padding-inline: var(--gap-x-small);
	--_color-content: var(--color-content-primary);
	--_color-background: var(--color-background-secondary);
	--_color-background-focus: color-mix(in hsl, var(--_color-background), var(--color-white) 20%);
	--_border-color: transparent;
	--_border-color-focus: var(--_color-content);
	--_font: var(--font-paragraph-medium);

	&[data-size="xx-small"] {
		--_padding-block: var(--gap-xxx-small);
		--_padding-inline: var(--gap-xx-small);
		--_font: var(--font-paragraph-small);
	}
	&[data-size="x-small"] {
		--_padding-block: var(--gap-xx-small);
		--_font: var(--font-paragraph-small);
	}
	&[data-size="small"] {
		--_font: var(--font-paragraph-small);
	}
	&[data-size="large"] {
		--_font: var(--font-paragraph-large);
	}

	&[data-error] {
		--_color-background: var(--color-background-light-negative);
		--_border-color: var(--color-negative);
	}

	&[data-positive] {
		--_color-background: var(--color-background-light-positive);
		--_border-color: var(--color-positive);
	}

	&[aria-disabled="true"], &[disabled] {
		--_color-content: var(--color-content-tertiary);
	}

	/* Special Cases */
	&[data-variant="search"] {
		inline-size: 100%;
	}

	display: block;
	box-sizing: border-box;
	inline-size: 100%;
	min-block-size: calc(1lh + var(--_padding-block) * 2 + var(--size-border) * 2);
	padding-block: var(--_padding-block);
	padding-inline: var(--_padding-inline);
	font: var(--_font);
	color: var(--_color-content);
	resize: none;
	border: var(--size-border) solid var(--_border-color);
	background-color: var(--_color-background);

	&::-webkit-calendar-picker-indicator {
		display: none;
	}
	&::-webkit-inner-spin-button {
		display: none;
	}

	&[type=number] {
		-moz-appearance: textfield;
	}

	body[data-inverse] & {
		--_color-content: var(--color-content-inverse-primary);
		--_color-background: var(--color-background-inverse-secondary);
	}

	&:focus {
		color: var(--_color-content);
		border-color: var(--_border-color-focus);
		background-color: var(--_color-background-focus);
		outline: none;
	}

	@media print {
		margin-block-start: var(--gap-x-small);
		border: 0;
		min-block-size: 0;
		block-size: auto !important; /* overwrite block-size from data-autosize */
		print-color-adjust: exact;
	}
}
