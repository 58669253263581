.a-button {
	--_color-content: var(--color-content-inverse-primary);
	--_color-content-disabled: var(--color-content-inverse-tertiary);
	--_color-background: var(--color-background-inverse-primary);
	--_font: var(--font-label-medium);
	--_border-radius: var(--corner-radius-button);
	--_padding-inline: var(--gap-small);
	--_padding-block: var(--gap-x-small);
	--_gap: var(--gap-xx-small);
	--_duration: var(--duration-medium);
	--_size-underline: var(--size-border);

	display: inline-flex;
	justify-content: center;
	align-items: center;
	column-gap: var(--_gap);
	font: var(--_font);
	color: var(--_color-content);
	text-decoration: none;
	padding-block: var(--_padding-block);
	padding-inline: var(--_padding-inline);
	white-space: nowrap;
	cursor: pointer;
	border: 0;
	border-radius: var(--_border-radius);
	background-color: var(--_color-background);
	transition-property: background-color, color;
	transition-duration: var(--_duration);
	touch-action: manipulation;

	> svg {
		block-size: 1.2em;
		block-size: 1lh;
		max-inline-size: none;
		inline-size: auto;
		aspect-ratio: 1;

		* {
			fill: currentcolor;
		}
	}

	&:is(:disabled, [aria-disabled="true"]) {
		cursor: not-allowed;
		color: var(--_color-content-disabled);
	}

	&[data-size="x-large"] {
		--_font: var(--font-label-x-large);
	}
	&[data-size="large"] {
		--_font: var(--font-label-large);
	}
	&[data-size="small"] {
		--_font: var(--font-label-small);
		--_padding-inline: var(--gap-x-small);
	}
	&[data-size="x-small"] {
		--_font: var(--font-label-x-small);
		--_padding-inline: var(--gap-x-small);
		--_padding-block: calc(6rem / 16);
	}

	&[data-shape="round"] {
		--_border-radius: 50%;
		--_padding-inline: var(--_padding-block);
		min-inline-size: calc(1lh + var(--_padding-inline) * 2);
		text-align: center;
	}
	&[data-shape="squared"] {
		--_padding-inline: var(--_padding-block);
		min-inline-size: calc(1lh + var(--_padding-inline) * 2);
		text-align: center;
	}
	&[data-shape="text"] {
		--_color-content: var(--color-content-primary);
		--_color-content-disabled: var(--color-content-tertiary);
		--_color-background: transparent;
		--_padding-inline: 0;
		--_padding-block: 0;

		> span:not(.a-button__icon) {
			margin-block: -0.18em;
			padding-block: 0.18em;
			background-image: linear-gradient(currentcolor, currentcolor);
			background-size: 0 var(--_size-underline);
			background-repeat: no-repeat;
			background-position: 0 bottom;
			transition-property: background-size;
			transition-duration: inherit;
		}
	}

	&[data-kind="secondary"] {
		--_color-content: var(--color-content-primary);
		--_color-content-disabled: var(--color-content-tertiary);
		--_color-background: var(--color-background-tertiary);

		&[data-shape="text"] {
			--_color-content: var(--color-content-secondary);
			--_color-content-disabled: var(--color-content-tertiary);
			--_color-background: transparent;
		}

		&[data-theme="negative"] {
			--_color-content: var(--color-negative);
		}
	}
	&[data-kind="tertiary"] {
		--_color-content: var(--color-content-primary);
		--_color-content-disabled: var(--color-content-tertiary);
		--_color-background: transparent;
	}


	/* States */
	&[data-selected] {
		&[data-kind="secondary"] {
			--_color-content: var(--color-content-inverse-primary);
			--_color-background: var(--color-background-inverse-primary);
		}
		&[data-kind="tertiary"] {
			--_color-content: var(--color-content-inverse-primary);
			--_color-background: var(--color-background-inverse-primary);
		}
		&[data-shape="text"] {
			> span:not(.a-button__icon) {
				background-size: 100% var(--_size-underline);
			}
		}
	}
	&[data-loader] {
		position: relative;
		color: color-mix(in hsl, var(--_color-content), transparent 80%);
		cursor: auto;

		&::before {
			position: absolute;
			content: '';
			block-size: 1.2em;
			inline-size: 1.2em;
			block-size: 1lh;
			inline-size: 1lh;
			mask-image: url(/assets/images/icons/loader.svg);
			mask-size: 1.2em;
			mask-size: 1lh;
			background-color: var(--_color-content);
			animation-name: rotate;
			animation-duration: var(--duration-rotate-loader);
			animation-timing-function: linear;
			animation-iteration-count: infinite;
		}
	}
	&[data-inverse],
	body[data-inverse] & {
		--_color-content: var(--color-content-primary);
		--_color-background: var(--color-background-primary);

		&[data-shape="text"] {
			--_color-content: var(--color-content-inverse-primary);
			--_color-background: transparent;
		}
		&[data-kind="secondary"] {
			--_color-content: var(--color-content-inverse-primary);
			--_color-background: var(--color-background-inverse-secondary);

			&[data-shape="text"] {
				--_color-content: var(--color-content-inverse-secondary);
				--_color-background: transparent;
			}
		}
		&[data-kind="tertiary"] {
			--_color-content: var(--color-content-inverse-primary);
			--_color-background: transparent;
		}
	}

	/* Special Cases */
	&[data-variant="navigation-text"] {
		--_padding-block: var(--gap-small);
		--_padding-inline: var(--gap-x-small);

		@media not (min-width: 61em) {
			--_padding-inline: var(--gap-xx-large);

			display: flex;
			justify-content: start;
			font: var(--font-label-x-large);
		}
		@media (min-width: 66em) {
			--_padding-inline: var(--gap-small);
		}
		@media (min-width: 70em) {
			--_padding-inline: var(--gap-medium);
		}
		@media (min-width: 77em) {
			--_padding-inline: var(--gap-large);
		}
	}
	&[data-variant="navigation-text-secondary"] {
		--_padding-block: var(--gap-small);
		--_padding-inline: var(--gap-x-small);

		li:last-child > & {
			margin-inline-end: calc(var(--_padding-inline) * -1);
		}

		@media not (min-width: 61em) {
			--_color-content: var(--color-content-secondary);
			--_size-underline: calc(1.5rem / 16);
			--_font: var(--font-label-small);
		}
		@media (min-width: 66em) {
			--_padding-inline: var(--gap-small);
		}
		@media (min-width: 70em) {
			--_padding-inline: var(--gap-medium);
		}
		@media (min-width: 77em) {
			--_padding-inline: var(--gap-large);
		}
	}
	&[data-variant="navigation-round"] {
		--_padding-block: var(--gap-x-small);
		--_padding-inline: var(--gap-x-small);

		@media not (min-width: 61em) {
			/* Offscreen navigation */
			--_font: var(--font-label-large);
		}
		@media (min-width: 66em) {
			--_padding-block: var(--gap-small);
			--_padding-inline: var(--gap-small);
		}
	}
	/* Appears only, on focus */
	&[data-variant="navigation-popover-button"] {
		margin-inline-start: calc((1lh + var(--_padding-inline)) * -1);
		margin-inline-end: calc((var(--_padding-inline)) * -1);
		align-self: center;
		pointer-events: none;
		opacity: 0;

		&:focus-visible {
			opacity: 1;
		}

		@media not (min-width: 61em) {
			display: none;
		}
	}

	@media (max-width: 32em) {
		&[data-size="small"]:not([data-variant], [data-shape="text"]) {
			--_padding-inline: var(--gap-x-small);
		}
	}

	@media (pointer: fine) {
		&:not(:where(:disabled, [aria-disabled="true"], [data-loader])) {
			--_color-background-mix: var(--color-white);

			&[data-kind="secondary"],
			&[data-kind="tertiary"] {
				--_color-background-mix: var(--color-black);
			}

			&[data-inverse],
			body[data-inverse] & {
				--_color-background-mix: var(--color-black);

				&[data-kind="secondary"],
				&[data-kind="tertiary"] {
					--_color-background-mix: var(--color-white);
				}
			}

			&:hover, a:hover &[data-fake-link] {
				background-color: color-mix(in hsl, var(--_color-background), var(--_color-background-mix) 15%);
			}
			&:active {
				background-color: color-mix(in hsl, var(--_color-background), var(--_color-background-mix) 20%);
				transition-duration: var(--duration-x-small);
			}
			&[data-kind="secondary"] {
				&:hover, a:hover &[data-fake-link] {
					background-color: color-mix(in hsl, var(--_color-background), var(--_color-background-mix) 4%);
				}
				&:active {
					background-color: color-mix(in hsl, var(--_color-background), var(--_color-background-mix) 8%);
				}
			}
			&[data-kind="tertiary"] {
				&:hover, a:hover &[data-fake-link] {
					background-color: color-mix(in hsl, var(--_color-background), var(--_color-background-mix) 8%);
				}
				&:active {
					background-color: color-mix(in hsl, var(--_color-background), var(--_color-background-mix) 12%);
				}
			}
			&[data-shape="text"] {
				&:hover, a:hover &[data-fake-link] {
					background-color: transparent;
					> span:not(.a-button__icon) {
						background-size: 100% var(--_size-underline);
					}
				}
				&:active {
					color:  color-mix(in hsl, var(--_color-content), var(--_color-background) 30%);
				}
			}
		}
	}

	@media print {
		display: none;
	}
}
:where(.a-text) + .a-button {
	margin-block-start: var(--gap-medium);
}
:where(.a-display, .a-heading) + .a-button {
	margin-block-start: var(--gap-large);
}

.a-button__icon {
	--_size: 1.2em;
	--_size: 1lh;
	--_delay: 0s;

	margin-inline-end: calc(var(--gap-xxx-small) * -1);
	flex-shrink: 0;

	.a-button[data-shape="text"] & {
		--_delay: calc(var(--_duration) * 0.5);
	}

	> svg {
		inline-size: var(--_size);
		block-size: var(--_size);
	}

	> svg * {
		fill: currentcolor;
	}

	&:where([data-variant="arrow-right"], [data-variant="arrow-left"]) {
		polygon {
			transition-property: translate, scale;
			transition-duration: var(--duration-small);
		}
	}
	&:where([data-variant="arrow-left"]) {
		polygon:last-child {
			transform-origin: right center;
		}
	}
	&:where([data-variant="hamburger"]) {
		polygon {
			position: relative;
			transition-property: transform, opacity;
			transition-duration: var(--duration-small);
			transform-origin: center center;
		}

		.a-button[data-active] & {
			polygon {
				&:nth-of-type(1) {
					transform: rotate(45deg) translateY(calc(6rem / 16));
				}
				&:nth-of-type(2) {
					opacity: 0;
				}
				&:nth-of-type(3) {
					transform: rotate(-45deg) translateY(calc(-6rem / 16));

				}
			}
		}
	}

	@media (pointer: fine) {
		.a-button:not(:where(:disabled, [aria-disabled="true"], [data-loader])) & {
			.a-button:hover &[data-variant="arrow-right"],
			a:hover .a-button[data-fake-link] &[data-variant="arrow-right"] {
				polygon {
					transition-delay: var(--_delay);
				}
				polygon:first-child {
					translate: 2px 0;
				}
				polygon:last-child {
					scale: 105% 1;
				}
			}
			.a-button:hover &[data-variant="arrow-left"],
			a:hover .a-button[data-fake-link] &[data-variant="arrow-left"] {
				polygon {
					transition-delay: var(--_delay);
				}
				polygon:first-child {
					translate: -2px 0;
				}
				polygon:last-child {
					scale: 105% 1;
				}
			}
		}
	}
}
