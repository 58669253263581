html {
	font-family: var(--font-family);
	-webkit-hyphens: auto;
	-webkit-hyphenate-limit-after: var(--hyphenate-limit);
	-webkit-hyphenate-limit-before: var(--hyphenate-limit);

	&[data-no-scroll] {
		overflow: hidden!important;
		block-size: 100%;
	}

	@supports (hyphenate-limit-chars: 16 8 8) {
		html {
			hyphens: auto;
			hyphenate-limit-chars: calc(var(--hyphenate-limit) * 2) var(--hyphenate-limit) var(--hyphenate-limit)
		}
	}

	@media print {
		font-size: 6.4pt;
	}
}

body {
	font: var(--font-paragraph-medium);
	display: flex;
	flex-direction: column;

	&[data-inverse] {
		color: var(--color-content-inverse-primary);
		background-color: var(--color-background-inverse-primary);
	}

	html[data-no-scroll] & {
		overflow: hidden;
		block-size: 100%;
	}

	@media print {
		display: block;
	}
}
