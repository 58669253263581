.m-pcon-configurator {
	> iframe {
		inline-size: 100%;
		aspect-ratio: 16 / 10;
		max-block-size: calc(100vh - var(--space-large));
		background-color: var(--color-background-tertiary);
	}

	@media not (min-width: 600px) {
		> iframe {
			aspect-ratio: 10 / 16;
		}
	}
}
