.o-product {
	margin-inline: auto;
	margin-block-start: var(--space-x-small);
	margin-block-end: var(--space-medium);
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	inline-size: 100%;
	padding-inline: var(--margin-inline-body);

	@media (min-width: 61em) {
		margin-block-start: var(--space-large);
	}

	h2 {
		margin-block-start: var(--gap-grid);
		font: var(--font-heading-medium);

		a {
			text-decoration: none;
		}

		small {
			font: inherit;
			font-weight: 400;
		}
	}

	ul {
		margin-block-start: var(--space-small);
		display: grid;
		row-gap: var(--gap-large);
		list-style: none;
	}

	@media (min-width: 61em) {
		ul {
			grid-template-columns: repeat(12, 1fr);
			column-gap: var(--gap-grid);
			row-gap: var(--gap-grid);
			margin-block-start: var(--gap-large);

			> * {
				grid-column-end: span 4;
			}
		}
	}

	@media (pointer: fine) {
		h2 a:where(:focus-visible, :hover) {
			text-decoration: underline;
		}
	}
}
