.a-description-list {
	--_font: var(--font, var(--font-paragraph-medium));
	--_font-dt: var(--font-dt, var(--font-heading-small));
	--_gap-heading: var(--gap-grid);
	--_gap-paragraph: var(--gap-small);

	&[data-size="large"] {
		--_font: var(--font, var(--font-paragraph-large));
		--_font-dt: var(--font-dt, var(--font-heading-medium))
	}
	&[data-size="small"] {
		--_font: var(--font, var(--font-paragraph-small));
		--_font-dt: var(--font-dt, var(--font-heading-small))
	}

	container-type: inline-size;
	font: var(--_font);
}

.a-description-list__container {
	display: flex;
	flex-direction: column;
	row-gap: var(--_gap-heading);

	@container (min-width: 900px) {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		column-gap: var(--gap-grid);

		> * {
			grid-column-end: span 4;
		}
	}
}

.a-description-list__item {
	> dt {
		font: var(--_font-dt);
		text-wrap: balance;
	}

	> dd {
		margin-block-start: var(--_gap-paragraph);
		text-wrap: balance;
	}
}
