vi-related-pages {
	--_columns: 1;
	--_gap: var(--gap-medium);
	--_flex-basis: calc(240rem / 16);

	ul {
		position: relative;
		display: flex;
		column-gap: var(--_gap);
		list-style: none;
	}
	ul > li {
		flex-basis: var(--_flex-basis);
		flex-shrink: 0;
	}


	@media (min-width: 45em) {
		--_flex-basis: calc(280rem / 16);
	}
	@media not (min-width: 65em) {
		ul > li {
			scroll-snap-align: start;
			scroll-margin-inline-start: var(--margin-inline-body);

			&:last-child {
				box-sizing: content-box;
				padding-inline-end: calc(100vw - var(--margin-inline-body) - var(--_flex-basis));
			}
		}
	}
	@media (min-width: 65em) {
		--_columns: 3;
		--_gap: var(--gap-grid);
		--_flex-basis: calc(100% / var(--_columns) - (var(--_gap) * ((var(--_columns) - 1)) / var(--_columns)));

		&:not([columns]) {
			ul > li:nth-child(3n - 2) {
				scroll-snap-align: start;
			}
		}

		&[columns="2"] {
			--_columns: 2;

			ul > li:nth-child(2n - 1) {
				scroll-snap-align: start;
			}
		}
	}
}
