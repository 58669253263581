/**
 * Container for text content. Can contain headings, paragraphs, tables.
 * Usually Kirby’s writer field or markdown output.
 * Default sizes start with `-large` (not `-medium`) to ensure better font size for reading.
 * Devs: Make sure to synchronize with a-heading
 */
.a-text {
	--_font: var(--font, var(--font-paragraph-medium));
	--_gap-paragraph: var(--gap-small);
	--_gap-list: var(--gap-x-small);

	max-inline-size: var(--inline-size-text);
	font: var(--_font);

	* + :where(h2, h3, h4, h5, h6) {
		margin-block-start: var(--gap-large);
	}

	p, ol, ul, table {
		* + & {
			margin-block-start: var(--_gap-paragraph);
		}
	}

	ol, ul {
		li + li {
			margin-block-start: var(--_gap-list);
		}
		li {
			* + :is(ul, ol) {
				margin-block-start: calc(var(--_gap-list) * 0.5);
			}
		}
	}
	ul {
		li {
			position: relative;
			list-style: none;
			&::before {
				content: ''; /* Necessary for the pseudo-element to be generated */
				position: absolute;
				inset-inline-start: calc(var(--gap-medium) * -1);
				inset-block-start: calc(1.4em * 0.5);
				inset-block-start: calc(1lh * 0.5);
				transform: translateY(-50%);
				width: calc(4rem / 16);
				height: calc(4rem / 16);
				background-color: currentcolor;
				border-radius: 50%;
			}

			ul {
				margin-inline-start: var(--gap-medium);
			}

		}
	}

	h2 {
		font: var(--font-heading-large);
	}
	h3 {
		font: var(--font-heading-medium);
	}
	h3 {
		font: var(--font-heading-small);
	}
	h4 {
		font: var(--font-heading-x-small);
	}

	a {
		word-wrap: break-word;
	}

	table {
		th {
			text-align: start;
		}
		tr:not(:last-child) > * {
			padding-block-end: calc(var(--_gap-list) * 0.5);
		}
		tr > *:not(:last-child) {
			padding-inline-end: var(--gap-small);
		}
	}

	&[data-size="x-large"] {
		--_font: var(--font, var(--font-paragraph-x-large));
	}
	&[data-size="large"] {
		--_font: var(--font, var(--font-paragraph-large));
	}
	&[data-size="small"] {
		--_gap-paragraph: var(--gap-x-small);
		--_font: var(--font, var(--font-paragraph-small));

		h2 {
			font: var(--font-heading-medium);
		}
		h3 {
			font: var(--font-heading-small);
		}
		h3 {
			font: var(--font-heading-x-small);
		}
		h4 {
			font: var(--font-heading-xx-small);
		}
	}
	&[data-size="x-small"] {
		--_font: var(--font, var(--font-paragraph-x-small));
	}

	&[data-kind="secondary"] {
		color: var(--color-content-secondary);
	}
	&[data-kind="tertiary"] {
		color: var(--color-content-tertiary);
	}

	&[data-theme="error"] {
		padding: var(--gap-small);
		color: var(--color-negative);
	}
	&[data-theme="notice"] {
		padding: var(--gap-small);
		background-color: var(--color-background-secondary)
	}

	&[data-balance] {
		text-wrap: balance;
	}

	@media (max-width: 61em) {
		ul {
			padding-inline-start: calc(28rem / 16)
		}
	}

	@media (pointer: fine) {
		a:hover {
			color: color-mix(in hsl, currentcolor, transparent 20%);
		}
		a:active {
			color: color-mix(in hsl, currentcolor, transparent 35%);
		}
	}
	@media print {
		h2 {
			font: var(--font-heading-medium);
		}
		h3 {
			font: var(--font-heading-small);
		}
		h3 {
			font: var(--font-heading-x-small);
		}
		h4 {
			font: var(--font-heading-xx-small);
		}
	}
}
.a-text + .a-text {
	margin-block-start: var(--_gap-paragraph);
}
:where(.a-display, .a-heading, .m-figure) + .a-text {
	margin-block-start: var(--gap-large);
}
