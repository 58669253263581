.m-form {
	container-type: inline-size;

	@media (min-width: 61em) {
		&[data-variant="checkout"] {
			block-size: 100%;
		}
	}
}

.m-form__container {
	display: flex;
	flex-direction: column;
	align-items: start;
	column-gap: var(--gap-grid);
	row-gap: var(--gap-large);

	.m-form[data-size="small"] & {
		row-gap: var(--gap-small);
	}

	> * + h2 {
		margin-block-start: var(--gap-xxx-large);
	}

	> hr {
		border-block-start: 0;
	}

	@container (min-width: 688px) {
		display: grid;
		grid-template-columns: repeat(12, 1fr);

		> * {
			--_span: var(--span, 12);

			grid-column-end: span var(--_span);
		}
	}
	@media (min-width: 61em) {
		.m-form[data-variant="checkout"] & {
			block-size: 100%;
			align-content: start;
		}

		> h2 {
			margin-block-end: var(--gap-small);
		}
	}
}

.m-form__error {
	margin-block-start: var(--gap-large);
	color: var(--color-negative);

	> p + p {
		margin-block-start: var(--gap-small);
	}
}

.m-form__submit-buttons {
	margin-block-start: var(--space-x-small);
}

.m-form__success {
	margin-block-start: var(--gap-large);
	padding: var(--gap-medium);
	color: var(--color-content-inverse-primary);
	background-color: var(--color-background-positive);
}


/* Used in checkout form */
.m-form__payment-methods {
	margin-block-start: var(--gap-xxx-large);
	display: inherit;
	flex-direction: inherit;
	align-items: inherit;
	row-gap: inherit;
	align-self: stretch;

	@container (min-width: 688px) {
		display: grid;
		grid-template-columns: subgrid;

		> * {
			--_span: var(--span, 12);

			grid-column-end: span var(--_span);
		}
	}

	@media (min-width: 61em) {
		position: sticky;
		inset-block-start: var(--gap-xxx-large);
	}
}

.m-form__discount {
	inline-size: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	row-gap: var(--gap-medium);
	column-gap: var(--gap-medium);

	@container (min-width: 512px) {
		flex-direction: row;
		align-items: end;
	}
}
