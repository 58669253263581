/* Typography */
.a-display {
	max-inline-size: var(--inline-size-text);
	font: var(--font-display-medium);
	text-wrap: balance;

	> small {
		font: inherit;
		color: var(--color-content-secondary);
	}

	&[data-size="xx-large"] {
		font: var(--font-display-xx-large);
	}
	&[data-size="x-large"] {
		font: var(--font-display-x-large);
	}
	&[data-size="large"] {
		font: var(--font-display-large);
	}
	&[data-size="small"] {
		font: var(--font-display-small);
	}

	@media print {
		&[data-size="xx-large"] {
			font: var(--font-display-large);
		}
		&[data-size="x-large"] {
			font: var(--font-display-medium);
		}
		&[data-size="large"] {
			font: var(--font-display-small);
		}
		&[data-size="small"] {
			font: var(--font-heading-medium);
		}
	}
}
