.o-product-types {
	margin-block-start: var(--space-small);
	margin-inline: auto;
	inline-size: 100%;
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	padding-inline: var(--margin-inline-body);

	@media (min-width: 61em) {
		margin-block-start: var(--space-large);
	}
}

.o-product-types__container {
	margin-block-start: var(--space-small);
	display: grid;
	column-gap: var(--gap-grid);

	@media (min-width: 61em) {
		grid-template-columns: 1fr 1fr;
		margin-block-start: var(--space-medium);
	}
}

.o-product-types__pagination {
	margin-block-start: var(--space-small);

	@media (min-width: 61em) {
		margin-block-start: var(--space-medium);
	}
}
