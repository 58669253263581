.hbspt-form {
	container-type: inline-size;
}

.hs-form {
	display: flex;
	flex-direction: column;
	align-items: start;
	column-gap: var(--gap-grid);
	row-gap: var(--gap-large);

	.m-form[data-size="small"] & {
		row-gap: var(--gap-small);
	}

	@container (min-width: 688px) {
		display: grid;
		grid-template-columns: repeat(12, 1fr);

		> * {
			--_span: var(--span, 12);

			grid-column-end: span var(--_span);
		}

		.m-grid & {
			grid-template-columns: subgrid;
		}
	}
}

.hs-form-field {
	inline-size: 100%;
	display: grid;
	gap: var(--gap-x-small);

	> label {
		font: var(--font-label-medium);
		color: var(--color-content-secondary);
	}
}

.hs-form-required {
	&::before {
		content: ' ';
	}
}

.hs-error-msgs {
	list-style: none;
	color: var(--color-negative);
}

.hs-input {
	--_padding-block: var(--gap-xx-small);
	--_padding-inline: var(--gap-x-small);
	--_color-content: var(--color-content-primary);
	--_color-background: var(--color-background-secondary);
	--_color-background-focus: var(--color-background-secondary);
	--_border-color: transparent;
	--_border-color-focus: var(--_color-content);
	--_font: var(--font-paragraph-medium);

	&.error {
		--_color-background: var(--color-background-light-negative);
		--_border-color: var(--color-negative);
	}

	&[aria-disabled="true"], &[disabled] {
		--_color-content: var(--color-content-tertiary);
	}

	display: block;
	box-sizing: border-box;
	inline-size: 100%;
	min-block-size: calc(1lh + var(--_padding-block) * 2 + var(--size-border) * 2);
	padding-block: var(--_padding-block);
	padding-inline: var(--_padding-inline);
	font: var(--_font);
	color: var(--_color-content);
	resize: none;
	border: var(--size-border) solid var(--_border-color);
	background-color: var(--_color-background);

	&::-webkit-calendar-picker-indicator {
		display: none;
	}
	&::-webkit-inner-spin-button {
		display: none;
	}

	&[type=number] {
		-moz-appearance: textfield;
	}

	body[data-inverse] & {
		--_color-content: var(--color-content-inverse-primary);
		--_color-background: var(--color-background-inverse-secondary);
		--_color-background-focus: var(--color-background-inverse-secondary);
	}

	&:focus {
		color: var(--_color-content);
		border-color: var(--_border-color-focus);
		background-color: color-mix(in hsl, var(--color-background-secondary), var(--color-white) 20%);
		outline: none;
	}

	@media print {
		border: 0;
		padding: 0;
		min-height: none;
	}
}

select.hs-input {
	--_border-color: transparent;
	--_padding-block: var(--gap-xx-small);
	--_padding-inline-start: var(--gap-x-small);
	--_padding-inline-end: var(--gap-xxx-small);
	--_size-icon: var(--size-icon-medium);
	--_background-color: var(--color-background-secondary);

	&:invalid {
		color: var(--color-content-secondary);
	}

	&:focus-visible {
		outline: none;
		--_background-color: color-mix(in hsl, var(--color-background-secondary), var(--color-white) 20%);
		--_border-color: var(--color-content-primary);
	}

	padding-inline-start: var(--_padding-inline-start);
	padding-inline-end: calc(var(--_size-icon) + var(--_padding-inline-end) * 2);
	padding-block: var(--_padding-block);
	font: var(--_font);
	border-radius: 0;
	appearance: none;
	color: var(--color-content-primary);
	border: var(--size-border) solid var(--_border-color);
	background-size: var(--_size-icon);
	background-image: url(/assets/images/icons/arrow-drop-down.svg);
	background-repeat: no-repeat;
	background-position: right var(--_padding-inline-end) center;
	background-color: var(--_background-color);
	transition-property: background-color, color;
	transition-duration: var(--duration-small);
}

input[type="checkbox"].hs-input {
	opacity: 0;
	width: 0px;
	height: 0px;
	overflow-x: hidden;
	overflow-y: hidden;
	margin-top: 0px;
	padding-top: 0px;
	position: absolute;
}

.hs-button {
	--_color-content: var(--color-content-inverse-primary);
	--_color-content-disabled: var(--color-content-inverse-tertiary);
	--_color-background: var(--color-background-inverse-primary);
	--_font: var(--font-label-medium);
	--_border-radius: var(--corner-radius-button);
	--_padding-inline: var(--gap-small);
	--_padding-block: var(--gap-x-small);
	--_gap: var(--gap-xx-small);
	--_duration: var(--duration-medium);
	--_size-underline: var(--size-border);

	display: inline-flex;
	justify-content: center;
	align-items: center;
	column-gap: var(--_gap);
	font: var(--_font);
	color: var(--_color-content);
	text-decoration: none;
	padding-block: var(--_padding-block);
	padding-inline: var(--_padding-inline);
	white-space: nowrap;
	cursor: pointer;
	border: 0;
	border-radius: var(--_border-radius);
	background-color: var(--_color-background);
	transition-property: background-color, color;
	transition-duration: var(--_duration);
	touch-action: manipulation;
}

.inputs-list {
	list-style: none;
}

.inputs-list.multi-container {
	> li + li {
		margin-block-start: var(--gap-x-small);
	}
}

.hs-form-checkbox-display,
.hs-form-booleancheckbox-display {
	position: relative;
	display: grid;
	align-items: start;
	grid-template-columns: calc(20rem / 16) 1fr;
	column-gap: var(--gap-small);
	cursor: pointer;

	&::after,
	&::before {
		content: '';
		box-sizing: border-box;
		position: absolute;
		left: 0;
		top: 0;
		block-size: calc(20rem / 16);
		inline-size: calc(20rem / 16);
	}

	&::before {
		border: var(--size-border) solid var(--color-content-tertiary);
		transition-property: border, background-color;
		transition-duration: var(--duration-small);
	}

	&::after {
		content: '.';
		mask-image: url(/assets/images/icons/check-small.svg);
		mask-size: 100%;
		background-color: var(--color-white);
		scale: 0;
		opacity: 0;
		transition-property: opacity, scale;
		transition-duration: var(--duration-medium);
	}

	> span {
		grid-column-start: 2;
		margin-inline-start: 0 !important;
		font: var(--font-label-medium);
	}

	&:has(input:checked) {
		&::before {
			background-color: var(--color-background-inverse-primary);
			border-color: var(--color-background-inverse-primary);
		}

		&::after {
			scale: 1;
			opacity: 1;
		}
	}
}

.hs-dependent-field {
	margin-block: var(--gap-small);
}

/* like .a-text */
.hs-richtext + .hs-richtext {
	margin-block-start: var(--gap-small);
}


/* like .m-form__success */
.submitted-message {
	margin-block-start: var(--gap-large);
	padding: var(--gap-medium);
	color: var(--color-content-inverse-primary);
	background-color: var(--color-background-positive);
}
