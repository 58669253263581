.m-product {
	container-type: inline-size;
	border-block-start: var(--size-border) solid currentcolor;
}

.m-product__container {
	display: grid;
	padding-block: var(--gap-x-small);
	column-gap: var(--gap-small);
	row-gap: var(--gap-small);
	grid-template-columns: auto auto;
	grid-auto-flow: dense;
	align-items: center;
	font: var(--font-label-medium);
	font-weight: 400;

	@container (min-width: 500px) {
		grid-template-columns: auto auto min-content;
		row-gap: var(--gap-xx-small);
	}
}


.m-product__size {
	grid-column: 1 / span 1;
}

.m-product__title {
	display: flex;
	flex-direction: column;
}

.m-product__article {
	grid-row-start: 2;
	color: var(--color-content-secondary);
}

.m-product__price {
	grid-column-start: 2;
	text-align: end;
	justify-self: end;
	line-height: var(--line-height-label-xsmall);

	> small {
		display: block;
		font: inherit;
		color: var(--color-content-secondary);
	}

	@container (min-width: 500px) {
		> small {
			display: inline;
			font: var(--font-label-small);
			font-weight: 400;
		}
	}
}

.m-product__quantity {
	grid-column-start: 4;
	justify-self: end;
	grid-row-start: 4;
}

.m-product__selector,
.m-product__options {
	grid-column: 1 / span 2;
	justify-self: end;

	@container (min-width: 500px) {
		grid-column: 2 / span 1;
		grid-row-start: 1;
	}
}

.m-product__selector {
	[slot="input"] {
		max-inline-size: calc(224rem / 16);
	}
}

.m-product__buttons-select {
	grid-column: 1 / span 2;
	justify-self: end;
	display: flex;
	gap: var(--gap-small);

	@container (min-width: 500px) {
		grid-column: 3 / -1;

		/* .m-product:has(.m-product__selector, .m-product__options) & {
			grid-row-start: 1;
		} */
	}
}

.m-product__buttons {
	display: flex;
	flex-direction: row-reverse;
	gap: var(--gap-xx-small);
}

.m-product__mm-info {
	text-align: end;
	font: var(--font-label-small);
	font-weight: 400;
	color: var(--color-content-secondary);
}
