.a-select {
	--_border-color: transparent;
	--_padding-block: var(--gap-xx-small);
	--_padding-inline-start: var(--gap-x-small);
	--_padding-inline-end: var(--gap-xxx-small);
	--_size-icon: var(--size-icon-medium);
	--_background-color: var(--color-background-secondary);

	&[data-text-align="end"] {
		text-align: end;
	}

	&:invalid {
		color: var(--color-content-secondary);
	}

	&:focus-visible {
		outline: none;
		--_background-color: var(--color-background-secondary);
		--_border-color: var(--color-content-primary);
	}

	&[data-size="x-small"] {
		--_padding-block: var(--gap-xxx-small);
		--_font: var(--font-paragraph-x-small);
		--_size-icon: var(--size-icon-small);
	}

	&[data-size="small"] {
		--_padding-block: var(--gap-xx-small);
		--_font: var(--font-paragraph-small);
		--_size-icon: var(--size-icon-medium);
	}

	padding-inline-start: var(--_padding-inline-start);
	padding-inline-end: calc(var(--_size-icon) + var(--_padding-inline-end) * 2);
	padding-block: var(--_padding-block);
	font: var(--_font);
	border-radius: 0;
	appearance: none;
	color: var(--color-content-primary);
	border: var(--size-border) solid var(--_border-color);
	background-size: var(--_size-icon);
	background-image: url(/assets/images/icons/arrow-drop-down.svg);
	background-repeat: no-repeat;
	background-position: right var(--_padding-inline-end) center;
	background-color: var(--_background-color);
	transition-property: background-color, color;
	transition-duration: var(--duration-small);
}
