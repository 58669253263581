/**
 * Used inside of .m-search__results.
 * Contains image (optional), title, subtitle (optional) and breadcrumbs (optional)
 */

.a-search-result {
	--_size-image: calc(72rem / 16);
	--_padding: var(--gap-medium);
	--_color-content: var(--color-content-primary);
	--_color-background: var(--color-background-primary);

	display: grid;
	grid-template-columns: var(--_size-image) minmax(10px, 1fr);
	column-gap: var(--gap-small);
	min-block-size: calc(var(--_size-image) + var(--_padding));
	padding-inline: var(--_padding);
	padding-block: calc(var(--_padding) * 0.5);
	text-decoration: none;
	color: var(--_color-content);
	background-color: var(--_color-background);
	transition-property: background-color;
	transition-duration: var(--duration-x-small);

	> img {
		inline-size: var(--_size-image);
		block-size: var(--_size-image);
	}

	&[data-size="large"] {
		padding-inline: 0;
		padding-block: var(--gap-small);
		border-block-end: var(--size-border) solid currentcolor;
	}

	&[aria-selected="true"] {
		background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 8%);
	}

	body[data-inverse] & {
		--_color-content: var(--color-content-inverse-primary);
		--_color-background: var(--color-background-inverse-primary);
	}

	@media (pointer: fine) {
		&:hover {
			background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 8%);
		}
		&:active {
			background-color: color-mix(in hsl, var(--_color-background), var(--_color-content) 12%);
		}
	}
}

.a-search-result__text {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	grid-column-start: 2;
	row-gap: var(--gap-xx-small);
	padding-block: var(--gap-xx-small);
}

.a-search-result__title {
	min-height: 2.4em;
	min-height: 2lh;
	display: flex;
	flex-direction: column;
	justify-content: start;
	font: var(--font-label-medium);

	> strong,
	> em {
		display: block;
		font-style: normal;
	}
}

.a-search-result__breadcrumbs {
	list-style: none;
	display: flex;
	color: var(--color-content-secondary);
	font: var(--font-label-x-small);

	> li:last-child {
		flex-shrink: 1;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}


	> li:not(:first-of-type) {
		list-style-type: ' / ';
		list-style-position: inside;
	}
}
