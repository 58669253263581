.o-project {
}

.o-project__related-system-footer {
	margin-block-start: var(--space-x-large);
	margin-inline: auto;
	max-inline-size: calc(var(--inline-size-content) + var(--gap-small) * 2);
	padding-inline: var(--gap-small);
	flex-direction: column;
	row-gap: var(--gap-small);
	container-type: inline-size;


	> div {
		display: grid;
		align-items: start;
		row-gap: var(--gap-medium);

		@container (min-width: 700px) {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			column-gap: var(--gap-grid);
			row-gap: var(--gap-xx-large);

			> h3 {
				grid-column: 1 / -1;
			}

			> * {
				grid-column-end: span 6;
			}
		}
		@container (min-width: 900px) {
			> * {
				grid-column-end: span 4;
			}
		}
	}
}

.o-project__related-projects {
	background-color: var(--color-background-secondary);
}
:where(.o-project__related-system-footer, .o-layouts) + .o-project__related-projects {
	margin-block-start: var(--space-x-large);
}
