.o-downloads {
	margin-inline: auto;
	max-inline-size: calc(var(--inline-size-content) + var(--margin-inline-body) * 2);
	padding-inline: var(--margin-inline-body);
}
.o-layouts + .o-downloads {
	margin-block-start: var(--space-large);
}

.o-downloads__filter {
	display: grid;
	row-gap: var(--gap-medium);
	grid-template-columns: minmax(10px, 1fr);

	@media (min-width: 36em) {
		grid-template-columns: repeat(2, 1fr);
		row-gap: var(--gap-x-large);
		column-gap: var(--gap-grid);

		> * {
			grid-column-end: span 2;
		}
	}

	@media (min-width: 55em) {
		grid-template-columns: repeat(12, 1fr);

		> * {
			--_span: var(--span, 4);
			grid-column-end: span var(--_span);
		}
	}
}

.o-downloads__filter-reset {
	margin-inline-start: calc(var(--gap-small) * -1);

	@media (min-width: 61em) {
		display: none;
	}
}

.o-downloads__list {
	margin-block-start: var(--space-large);
	display: grid;
	list-style: none;
	gap: var(--margin-inline-body);

	@media (min-width: 36em) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (min-width: 55em) {
		grid-template-columns: repeat(12, 1fr);
		gap: var(--gap-grid);
		> li {
			grid-column-end: span 4;
		}
	}
}

.o-downloads__pagination {
	margin-block-start: var(--space-small);

	@media (min-width: 61em) {
		margin-block-start: var(--space-medium);
	}
}
